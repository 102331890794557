/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  LatestOrderHistoryDocument,
  LatestOrderHistoryQuery,
  OrderHistoryDocument,
  OrderHistoryQuery,
  OrderStatusHistory,
  SortBy,
} from '@betotrace/shared/graphql/generated';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { OrderDetailsContext } from '@betotrace/modules/order/contexts';
import usePublicPath from '@betotrace/shared/hooks/usePublicPath/usePublicPath';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { updatedOrderVar } from '@betotrace/App';

const OrderHistoryContext = React.createContext({
  orderHistory: [] as OrderStatusHistory[],
  setUpdatedOrderHistory: (_: OrderStatusHistory[]) => {},
  latestOrderHistory: null as
    | LatestOrderHistoryQuery['orderLatestStatusHistory']
    | null,
  setLatestOrderHistory: (
    _value: LatestOrderHistoryQuery['orderLatestStatusHistory'] | null
  ) => {},
  orderHistoryPagination: null,
  setOrderHistoryPagination: (
    _value: OrderHistoryQuery['orderStatusHistories']['pageInfo'] | null
  ) => {},
});

export const OrderHistoryContextProvider = (props: {
  children: JSX.Element;
}) => {
  const updatedOrderData = useReactiveVar(updatedOrderVar);

  const orderDetailCtx = useContext(OrderDetailsContext);
  const isPublicPath = usePublicPath();

  const [orderHistory, setOrderHistory] = useState<OrderStatusHistory[]>([]);
  const [latestOrderHistory, setLatestOrderHistory] = useState<
    LatestOrderHistoryQuery['orderLatestStatusHistory'] | null
  >(null);
  const [orderHistoryPagination, setOrderHistoryPagination] =
    useState<OrderHistoryQuery['orderStatusHistories']['pageInfo']>(null);
  const params: { id: string } = useParams() as { id: string };

  const setUpdatedOrderHistory = (orderHistory: OrderStatusHistory[]) => {
    setOrderHistory(orderHistory);
  };
  const [orderStatusHistories, { loading: loadingHistory, data: dataHistory }] =
    useLazyQuery(OrderHistoryDocument);

  const [orderLatestStatusHistory] = useLazyQuery(LatestOrderHistoryDocument, {
    fetchPolicy: 'cache-first',
    onCompleted: (data) => {
      setLatestOrderHistory(data.orderLatestStatusHistory);
    },
  });

  useEffect(() => {
    if (params?.id && !orderDetailCtx?.orderData?.id) {
      orderStatusHistories({
        variables: {
          filter: {
            orderId: params?.id,
          },
          sort: {
            changedOn: SortBy.Desc,
          },
          page: {
            index: 0,
            size: 20,
          },
        },
      });

      if (isPublicPath) {
        orderLatestStatusHistory({
          variables: {
            request: {
              orderId: params?.id,
            },
          },
        });
      }
    }
    if (!loadingHistory && dataHistory?.orderStatusHistories?.items) {
      setOrderHistory(dataHistory.orderStatusHistories.items);
      setOrderHistoryPagination(dataHistory.orderStatusHistories.pageInfo);
    }
  }, [JSON.stringify(dataHistory)]);

  useEffect(() => {
    if (updatedOrderData && params?.id === updatedOrderData.id) {
      orderStatusHistories({
        fetchPolicy: 'cache-and-network',
        variables: {
          filter: {
            orderId: params?.id,
          },
          sort: {
            changedOn: SortBy.Desc,
          },
          page: {
            index: 0,
            size: 20,
          },
        },
      });

      orderLatestStatusHistory({
        fetchPolicy: 'cache-and-network',
        variables: {
          request: {
            orderId: params?.id,
          },
        },
      });
    }
  }, [updatedOrderData]);

  useEffect(() => {
    orderStatusHistories({
      fetchPolicy: 'cache-first',
      variables: {
        filter: {
          orderId: params?.id,
        },
        sort: {
          changedOn: SortBy.Desc,
        },
        page: {
          index: 0,
          size: 20,
        },
      },
    });
    
    orderLatestStatusHistory({
      fetchPolicy: 'cache-and-network',
      variables: {
        request: {
          orderId: params?.id,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (!params?.id && orderDetailCtx?.orderData?.id) {
      orderStatusHistories({
        variables: {
          filter: {
            orderId: orderDetailCtx?.orderData?.id,
          },
          sort: {
            changedOn: SortBy.Desc,
          },
          page: {
            index: 0,
            size: 20,
          },
        },
      });

      if (isPublicPath) {
        orderLatestStatusHistory({
          variables: {
            filter: {
              orderId: orderDetailCtx?.orderData?.id,
            },
          },
        });
      }
    }
  }, [orderDetailCtx?.orderData?.id]);

  return (
    <OrderHistoryContext.Provider
      value={{
        orderHistory,
        setUpdatedOrderHistory,
        latestOrderHistory,
        setLatestOrderHistory,
        orderHistoryPagination,
        setOrderHistoryPagination,
      }}
    >
      {props.children}
    </OrderHistoryContext.Provider>
  );
};

export default OrderHistoryContext;
