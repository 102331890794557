import { OrderTypes } from '@betotrace/modules/order/pages/details/Details/hooks/useOrder';
import {
  AccessTime,
  Add,
  AssignmentTurnedIn,
  CreditCard,
  Group,
  Help,
  LibraryBooks,
  LocationCity,
  Settings as SettingsIcon,
} from '@mui/icons-material';

interface NavigationUrl {
  main: string;
  single?: string;
}
const Deliveries = { main: '/deliveries' };
const Customers = { main: '/customers' };
const Projects = { main: '/projects', single: '/project' };
const Orders = { main: '/orders', single: '/order' };
const Settings = { main: '/settings' };
const Default = { main: '/' };

export const useActivePathMethod = (pathname: string, user: string): string => {
  const matchesPath = (path: string, item: string): boolean =>
    path === item || path.includes(item);
  if (
    (matchesPath(pathname, Projects?.main) ||
      matchesPath(pathname, Projects?.single)) &&
    user === OrderTypes.customer
  ) {
    return '/';
  } else {
    return [Deliveries, Customers, Projects, Orders, Settings, Default].find(
      (item: NavigationUrl) =>
        matchesPath(pathname, item?.main) || matchesPath(pathname, item?.single)
    )?.main;
  }
};

interface MenuItem {
  name: string;
  navLink: string;
  Icon: React.ElementType;
  showOnMobile: boolean;
  disabled: boolean;
  openNewWindow?: boolean;
}
export const getPassiveMenuItems = (isPublic: boolean, user: OrderTypes) => {
  const mainItems: MenuItem[] = [
    {
      name: user === OrderTypes.supplier ? 'Orders' : 'Projects',
      navLink: Default?.main,
      Icon: user === OrderTypes.supplier ? AssignmentTurnedIn : LocationCity,
      showOnMobile: true,
      disabled: isPublic,
    },
    {
      name: user === OrderTypes.supplier ? 'Deliveries' : 'Orders',
      navLink: user === OrderTypes.supplier ? Deliveries?.main : Orders?.main,
      Icon: user === OrderTypes.supplier ? AccessTime : AssignmentTurnedIn,
      showOnMobile: true,
      disabled: isPublic,
    },
  ];

  if (user === OrderTypes.supplier) {
    mainItems.push({
      name: 'invite_customers',
      navLink: Customers?.main,
      Icon: Group,
      showOnMobile: true,
      disabled: isPublic,
    });
  }

  const middleItems: MenuItem[] = [
    {
      name: 'Invite client',
      navLink: Default?.main,
      Icon: Add,
      openNewWindow: true,
      showOnMobile: true,
      disabled: isPublic,
    },
  ];

  const secondaryItems: MenuItem[] = [
    {
      name: 'Settings',
      navLink: Settings?.main,
      Icon: SettingsIcon,
      showOnMobile: false,
      disabled: isPublic,
    },
    {
      name: 'Support',
      navLink: '',
      Icon: Help,
      openNewWindow: true,
      showOnMobile: true,
      disabled: isPublic,
    },
    {
      name: 'Guides',
      navLink: '',
      Icon: CreditCard,
      openNewWindow: true,
      showOnMobile: true,
      disabled: isPublic,
    },
    {
      name: 'Licence plan',
      navLink: '',
      Icon: LibraryBooks,
      openNewWindow: true,
      showOnMobile: true,
      disabled: isPublic,
    },
  ];

  return { mainItems, middleItems, secondaryItems };
};
