import { GoogleChromeIcon, MicrosoftEdgeIcon } from '@betotrace/assets/svg';
import { Box, Dialog, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

const OldBrowserNotification = () => {
  const intl = useIntl();

  const browsersBoxes = [
    {
      icon: <GoogleChromeIcon />,
      title: 'Google Chrome',
    },
    {
      icon: <MicrosoftEdgeIcon />,
      title: 'Microsoft Edge',
    },
  ];

  return (
    <Dialog maxWidth="md" open={true}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: '#2196F3',
          py: 2,
          px: 3,
        }}
      >
        <Typography
          sx={{
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '30px',
            letterSpacing: '0.15px',
            color: '#fff',
          }}
        >
          {intl.formatMessage({
            id: 'browser.notification',
            defaultMessage: 'Browser notification',
          })}
        </Typography>
      </Box>
      <Box
        sx={{ px: 6, py: 4, color: '#5A5A5A', '> p > a': { color: '#5A5A5A' } }}
      >
        <Typography>
          {intl.formatMessage({
            id: 'browser.notification.itSeems',
            defaultMessage: 'It seems you are using Internet Explorer.',
          })}
        </Typography>
        <Typography>
          {intl.formatMessage(
            {
              id: 'browser.notification.microsoftEnded',
              defaultMessage:
                'Microsoft has ended support for Internet Explorer. Please update your browser.',
            },
            {
              endedSupport: (
                <a
                  href="https://learn.microsoft.com/en-us/lifecycle/products/internet-explorer-11"
                  target="_blank"
                  rel="noreferrer"
                >
                  {intl.formatMessage({
                    id: 'browser.notification.microsoftEnded.endedSupport',
                    defaultMessage: 'ended support',
                  })}
                </a>
              ),
            }
          )}
        </Typography>
      </Box>
      <Box
        sx={{
          mt: 6,
          pb: 4,
          width: 'fit-content',
          margin: 'auto',
          textAlign: 'center',
        }}
      >
        <Typography sx={{ color: '#000' }}>
          {intl.formatMessage({
            id: 'browser.notification.bestExperience',
            defaultMessage: 'Betotrace is best experienced with',
          })}
        </Typography>

        <Box sx={{ mt: 3, display: 'flex', gap: 6 }}>
          {browsersBoxes.map((oneBrowser) => (
            <Box key={oneBrowser.title}>
              <Box
                sx={{
                  p: 4,
                  mb: 1.5,
                  width: 'fit-content',
                  border: '1px solid #D9D9D9',
                  borderRadius: '19px',
                }}
              >
                {oneBrowser.icon}
              </Box>
              <Typography>{oneBrowser.title}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Dialog>
  );
};

export default OldBrowserNotification;
