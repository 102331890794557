import { FONT, MARGIN } from '@betotrace/shared/libs/ui-elements';
import styled from 'styled-components';

export const SubTitle = styled.div.attrs(
  (props: { withCollapse?: boolean }) => props
)`
  text-align: start;
  font-size: ${FONT.SIZE.H4};
  line-height: 30px;
  font-weight: ${FONT.WEIGHT.BOLD};
  letter-spacing: 0.25px;
  color: ${(props) => (props?.withCollapse ? '#2196F3' : '#000')};
`;

export const SubDescription = styled.div.attrs(
  (props: { withCollapse?: boolean }) => props
)`
  text-align: start;
  font-size: ${FONT.SIZE.SMALL};
  line-height: 15px;
  font-weight: ${FONT.WEIGHT.NORMAL};
  margin-bottom: ${MARGIN.MD};
  letter-spacing: 0.4px;
`;

export const MapContainer = styled.div`
  margin-left: 0% !important;
`;
