import React from 'react';

const Login = React.lazy(() => import('@betotrace/modules/auth/login'));
const RegistrationPage = React.lazy(
  () => import('@betotrace/modules/auth/registration')
);
const ReOrderWizardComponent = React.lazy(
  () =>
    import(
      '@betotrace/modules/pages/NewStructure/Wizard/ReorderWizardComponent'
    )
);
const CreateProjectProductWizardComponent = React.lazy(
  () =>
    import(
      '@betotrace/modules/pages/NewStructure/Wizard/CreateProjectProductWizardComponent'
    )
);
const EditProjectProductWizardComponent = React.lazy(
  () =>
    import(
      '@betotrace/modules/pages/NewStructure/Wizard/EditProjectProductWizardComponent'
    )
);
const NewProject = React.lazy(
  () => import('@betotrace/modules/project/customer/pages/create')
);
const CloneProjectProductWizardComponent = React.lazy(
  () =>
    import(
      '@betotrace/modules/pages/NewStructure/Wizard/CloneProjectProductWizardComponent'
    )
);
const FromFavoriteProjectProductWizardComponent = React.lazy(
  () =>
    import(
      '@betotrace/modules/pages/NewStructure/Wizard/FromFavoriteProjectProductWizardComponent'
    )
);
const ProductOrderWizard = React.lazy(
  () =>
    import(
      '@betotrace/modules/pages/NewStructure/Wizard/ProductOrderWizardComponent'
    )
);
const Projects = React.lazy(() => import('../modules/project/customer/pages'));
// eslint-disable-next-line @typescript-eslint/no-unsafe-return
const SupplierProjects = React.lazy(
  () => import('../modules/project/supplier/pages')
);

const Order = React.lazy(() => import('../modules/order/pages/list'));
const Deliveries = React.lazy(() => import('../modules/deliveries/pages'));
const Settings = React.lazy(
  () => import('../modules/settings/pages/setting/Setting')
);
const OrderDetailsPage = React.lazy(
  () => import('@betotrace/modules/order/pages/details')
);
const ProjectDetails = React.lazy(
  () =>
    import('@betotrace/modules/project/customer/pages/details/ProjectDetails')
);
const OrderWizardComponent = React.lazy(
  () => import('../modules/pages/NewStructure/Wizard')
);
const CustomerPage = React.lazy(
  () => import('@betotrace/modules/customers/pages/list')
);
const SupplierDashboardPage = React.lazy(
  () =>
    import(
      'supplier-dashboard/pages/supplier-dashboard'
    )
);

interface PathsProps {
  path: string;
  exact?: boolean;
  protectedRoute?: boolean;
  component: React.LazyExoticComponent<() => JSX.Element>;
  isSupplier?: boolean;
}
const paths: PathsProps[] = [
  {
    path: '/deliveries',
    exact: true,
    component: Deliveries,
    protectedRoute: true,
  },
  {
    path: '/',
    exact: true,
    component: Projects,
    protectedRoute: true,
    isSupplier: false,
  },
  {
    path: '/projects',
    exact: true,
    component: SupplierProjects,
    protectedRoute: true,
    isSupplier: true,
  },
  {
    path: '/order/:orderId/reorder',
    exact: true,
    component: ReOrderWizardComponent,
    protectedRoute: true,
  },
  {
    path: '/order/new-flow',
    exact: true,
    component: OrderWizardComponent,
    protectedRoute: true,
  },
  {
    path: '/project/product/:productId/order',
    exact: true,
    component: ProductOrderWizard,
    protectedRoute: true,
  },
  {
    path: '/project/:projectId/product/new',
    exact: true,
    component: CreateProjectProductWizardComponent,
    protectedRoute: true,
  },
  {
    path: '/project/:projectId/product/new/clone/:productId',
    exact: true,
    component: CloneProjectProductWizardComponent,
    protectedRoute: true,
  },
  {
    path: '/project/:projectId/product/new/favorite/:favoriteId',
    exact: true,
    component: FromFavoriteProjectProductWizardComponent,
    protectedRoute: true,
  },
  {
    path: '/project/:projectId/product/:productId',
    exact: true,
    component: EditProjectProductWizardComponent,
    protectedRoute: true,
  },
  {
    path: '/order/:id',
    exact: true,
    component: OrderDetailsPage,
    protectedRoute: true,
  },
  {
    path: '/public/:userType/order/:id',
    exact: true,
    component: OrderDetailsPage,
    protectedRoute: false,
  },
  {
    path: '/project/new',
    exact: true,
    component: NewProject,
    protectedRoute: true,
  },
  {
    path: '/project/:id',
    exact: true,
    component: ProjectDetails,
    protectedRoute: true,
  },
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/registration',
    component: RegistrationPage,
  },
  {
    path: '/settings',
    component: Settings,
    protectedRoute: true,
  },
  {
    path: '/orders',
    exact: true,
    component: Order,
    protectedRoute: true,
    isSupplier: true,
  },
  {
    path: '/orders/:filter',
    exact: true,
    component: Order,
    protectedRoute: true,
    isSupplier: true,
  },
  {
    path: '/orders/:filter?',
    exact: true,
    component: Order,
    protectedRoute: true,
  },
  {
    path: '/customers',
    exact: true,
    component: CustomerPage,
    protectedRoute: true,
  },
  {
    path: '/',
    exact: true,
    component: SupplierDashboardPage,
    protectedRoute: true,
  },
];

export default paths;
