import React from 'react';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  DefaultOptions,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';

import { useAuthContext } from '@betotrace/shared/contexts';
import { getSearchQueries } from '@betotrace/modules/pages/NewStructure/utils/utils';

const httpLink = new HttpLink({ uri: window?._env_?.VITE_API_ENDPOINT });

const authMiddleware = (token: string) =>
  new ApolloLink((operation, forward) => {
    const queryValue = getSearchQueries();
    const localeParam = queryValue.get('locale');

    if (token) {
      operation.setContext({
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
    }
    if (!token && localeParam) {
      operation.setContext({
        headers: {
          'Content-Language': localeParam,
        },
      });
    }
    return forward(operation);
  });

const cache = new InMemoryCache({});
const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

export const useAppClient = () => {
  const { token } = useAuthContext();

  return new ApolloClient({
    link: authMiddleware(token).concat(httpLink),
    cache,
    defaultOptions,
  });
};

interface Props {
  children: React.ReactNode;
}

const ApolloProviderWrapper = ({ children }: Props) => {
  const client = useAppClient();
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloProviderWrapper;
