import { SIZE, WEIGHT } from './elements/typography';

export const FONT = {
  WEIGHT,
  SIZE,
};

export { GAP } from './elements/gap';
export { MARGIN } from './elements/margin';
export { PADDING } from './elements/padding';
export { BORDER, BORDER_PRESET } from './elements/border';
export { ICON } from './elements/icon';
export { GENERAL_COLORS, Colors as COLORS } from './elements/colors';
