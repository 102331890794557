import React from 'react';
import { CircularProgress, Grid } from '@mui/material';

export default function InitialLoader() {
  return (
    <Grid
      container
      justifyContent="center"
      spacing={0}
      alignItems="center"
      style={{ minHeight: '100vh' }}
    >
      <CircularProgress />
    </Grid>
  );
}
