import en from 'date-fns/locale/en-GB';
import { de, nl, fr, es } from 'date-fns/locale';
import { useAuthContext } from '@betotrace/shared/contexts';
import { languageEnum } from '@betotrace/shared/components/languageSelector/helper/helper';

const useDateLocale = () => {
  const { locale } = useAuthContext();

  const localeValue = () => {
    switch (locale) {
      case languageEnum.NL_BE:
      case languageEnum.NL_NL:
        return nl;
      case languageEnum.FR_BE:
        return fr;
      case languageEnum.DE:
        return de;
      case languageEnum.ES:
        return es;
      default:
        return en;
    }
  };

  return localeValue();
};

export default useDateLocale;
