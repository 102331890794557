import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  AuthContextProvider,
  PublicContextProvider
} from './src/shared/contexts';
import { ReactKeycloakProvider } from '@react-keycloak/web';
// import { registerSW } from 'virtual:pwa-register'
import './index.scss';
import { App } from '@betotrace/App';
import keycloakInstance from './src/configurations/keycloak';
import {
  SupplierBrandingProvider
} from "@betotrace/shared/contexts/supplierBranding/supplierBranding.context";


// registerSW({ immediate: true, onRegisterError: (e:string) => {
//     console.log(e);
//   } })

const RootComponent = () => {
  return (
    <SupplierBrandingProvider>
      <ReactKeycloakProvider authClient={keycloakInstance}>
        <PublicContextProvider>
          <AuthContextProvider>
            <App />
          </AuthContextProvider>
        </PublicContextProvider>
      </ReactKeycloakProvider>
    </SupplierBrandingProvider>
  );
};

const container = document.querySelector('#root');
const root = createRoot(container as Element);
root.render(<RootComponent />);
