export const WEIGHT = {
  EXTRA_BOLD: 800,
  BOLD: 700,
  SEMI_BOLD: 600,
  MEDIUM: 500,
  NORMAL: 400,
  LIGHT: 300,
};

export const SIZE = {
  // none (reserved)
  H1: '32px',
  // Statistics numbers
  H2: '24px',
  // None (reserved)
  H3: '22px',
  // Page header
  H4: '20px',
  // Page subheader
  H5: '18px',
  // Button Text
  H6: '16px',
  // Table content, Tabs, Side Directory
  NORMAL: '14px',
  // Statistic Table header, Table headers, Breadcrumbs
  SMALL: '12px',
};
