import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { GMapMarker } from '@betotrace/common';

const containerStyle = {
  height: '400px',
};

interface Props {
  defaultCenter?: google.maps.LatLng | google.maps.LatLngLiteral;
  pushPins?: GMapMarker[];
  onPinDrag?: (value: { latitude: number; longitude: number }) => void;
  editMode?: boolean;
  draggable?: boolean;
  mapStyle?: object;
  id: string;
}

const MapData = ({
  defaultCenter,
  pushPins,
  onPinDrag,
  draggable = false,
  editMode = false,
  mapStyle,
  id,
}: Props) => {
  const [map, setMap] = useState<google.maps.Map>(null);

  const mapCenter = useMemo(() => {
    return pushPins?.length > 0 ? pushPins[0].position : defaultCenter;
  }, [pushPins, defaultCenter]);

  const onDragMarker = (e: google.maps.MapMouseEvent) => {
    onPinDrag({
      latitude: e.latLng.lat(),
      longitude: e.latLng.lng(),
    });
  };

  const onLoad = useCallback((mapInstance: google.maps.Map) => {
    const bounds = new window.google.maps.LatLngBounds();
    if (pushPins?.length > 1) {
      pushPins.forEach((e) => bounds.extend(e.position));
      mapInstance.fitBounds(bounds);
    }
    setMap(mapInstance);
  }, []);

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      if (pushPins?.length > 1) {
        pushPins.forEach((e) => bounds.extend(e.position));
        map.fitBounds(bounds);
      }
      setMap(map);
    }
  }, [JSON.stringify(pushPins)]);

  return (
    <GoogleMap
      id={id}
      onLoad={onLoad}
      mapContainerStyle={mapStyle || containerStyle}
      center={mapCenter}
      zoom={pushPins?.length > 0 ? 19 : 10}
      options={{
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: true,
        scrollwheel: false,
      }}
    >
      {pushPins?.map((entry: GMapMarker, key: number) => (
        <Marker
          key={`map-push-pin-${key}-${id}`}
          position={entry.position}
          label={entry.label}
          onDragEnd={(e) => onDragMarker(e)}
          draggable={draggable && editMode}
          options={{}}
        />
      ))}
    </GoogleMap>
  );
};

export default React.memo(MapData);
