import * as React from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';

import { useAuthContext } from '@betotrace/shared/contexts';

interface PrivateRouteParams {
  component: React.ComponentType<RouteComponentProps> | React.ComponentType;
}

export default function ProtectedRoute({
  component: Component,
  ...rest
}: PrivateRouteParams) {
  const { isLoggedIn } = useAuthContext();

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
            }}
          />
        )
      }
    />
  );
}
