import { MouseEvent } from 'react';

interface SvgProps {
  width?: number | string;
  height?: number | string;
  color?: string;
  className?: string;
  onMouseEnter?: (event: MouseEvent) => void;
  onClick?: (event: MouseEvent) => void;
}

export const InfoIcon = (props: SvgProps) => {
  const { width = 48, height = 48, color = '' } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 50 50">
      <path
        fill={color}
        d="M22.65 34h3V22h-3ZM24 18.3q.7 0 1.175-.45.475-.45.475-1.15t-.475-1.2Q24.7 15 24 15q-.7 0-1.175.5-.475.5-.475 1.2t.475 1.15q.475.45 1.175.45ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 23.95q0-4.1 1.575-7.75 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24.05 4q4.1 0 7.75 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Z"
      />
    </svg>
  );
};

export const BTIcon = (props: SvgProps) => {
  const {
    width = 25,
    height = 25,
    color = '#E3E3E3',
    className = '',
    onMouseEnter,
    onClick,
  } = props;

  return (
    <svg
      width={width}
      height={height}
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={onMouseEnter}
      onClick={onClick}
      {...props}
    >
      <path
        d="M20.938 8.46c.966.013 1.932 0 2.897.01.213.001.305-.058.356-.275.247-1.034.505-2.065.78-3.092.077-.287-.006-.33-.272-.328-1.236.012-2.473-.005-3.71.012-.301.004-.402-.06-.397-.38.019-1.38.004-2.761.012-4.142.001-.243-.031-.315-.285-.187-1.248.625-2.504 1.239-3.76 1.85-.171.082-.238.184-.235.377.012.702-.007 1.403.01 2.105.009.281-.056.392-.363.378-.576-.025-1.154-.007-1.73-.007-.62 0-.62 0-.552.584.026.065.057.127.075.194.22.871.443 1.742.654 2.616.05.21.128.312.372.298.399-.025.801.013 1.2-.014.293-.02.362.094.343.358-.02.279-.008.56-.004.842.024 1.812-.08 3.626.05 5.434.141 1.993 1.582 3.43 3.58 3.608 1.444.13 2.894.023 4.34.06.268.007.236-.153.236-.317 0-1.076-.01-2.152.006-3.227.004-.262-.065-.33-.33-.33-.822-.004-1.648.07-2.468-.042-.682-.093-1.083-.477-1.113-1.154a56.835 56.835 0 0 1-.006-4.31c-.008-.197-.003-.397-.028-.593-.033-.275.088-.332.342-.329ZM8.323 4.617c-1.437-.186-2.791.016-3.966.943-.19-.056-.096-.212-.096-.316C4.254 3.622 4.257 2 4.254.377c0-.125.036-.258-.036-.377-.087.034-.177.061-.26.102C2.735.702 1.517 1.312.29 1.9c-.231.11-.29.243-.29.481.006 5.768.004 11.534.006 17.302 0 .099-.036.208.05.317.105-.047.21-.09.313-.14C1.581 19.262 2.793 18.67 4 18.065c.19-.095.324-.098.5.04.354.274.762.458 1.19.597 2.248.74 5.024-.038 6.646-1.895 1.525-1.745 2.052-3.798 1.787-6.067-.343-2.951-2.414-5.687-5.8-6.124Zm1.475 8.05c-.3 1.192-1.013 2.025-2.238 2.328-1.156.285-2.218.111-3.085-.782a.647.647 0 0 1-.2-.498c.012-.655.004-1.31.004-1.964 0-.643.006-1.287-.004-1.93a.624.624 0 0 1 .184-.468c.872-.938 1.963-1.115 3.139-.815 1.203.306 1.908 1.141 2.2 2.322.15.6.152 1.21 0 1.808Z"
        fill={color}
      />
    </svg>
  );
};

export const CopyIcon = (props: SvgProps) => {
  const { width = 24, height = 24, color = '#999' } = props;

  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <path
        fill={color}
        d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1Zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2Zm0 16H8V7h11v14Z"
      />
    </svg>
  );
};

export const OpenNewTabIcon = (props: SvgProps) => {
  const { width = 18, height = 18, color = '#2196F3' } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      {...props}
    >
      <path
        fill={color}
        d="M16 16H2V2h7V0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V9h-2v7ZM11 0v2h3.59l-9.83 9.83 1.41 1.41L16 3.41V7h2V0h-7Z"
      />
    </svg>
  );
};

export const GoogleChromeIcon = (props: SvgProps) => {
  const { width = 97, height = 97, color = 'none' } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={color}
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          fill="#fff"
          d="M48.501 72.749c13.394 0 24.251-10.858 24.251-24.252 0-13.393-10.857-24.251-24.251-24.251-13.394 0-24.251 10.858-24.251 24.251 0 13.394 10.857 24.252 24.251 24.252Z"
        />
        <path
          fill="#229342"
          d="m27.499 60.629-21-36.371a48.488 48.488 0 0 0 0 48.5A48.492 48.492 0 0 0 48.503 97l20.999-36.371v-.006a24.243 24.243 0 0 1-33.128 8.886 24.247 24.247 0 0 1-8.876-8.88Z"
        />
        <path
          fill="#FBC116"
          d="M69.501 60.627 48.503 97A48.49 48.49 0 0 0 90.497 24.25H48.499l-.005.004a24.248 24.248 0 0 1 21.008 36.374Z"
        />
        <path
          fill="#1A73E8"
          d="M48.501 67.702c10.603 0 19.199-8.596 19.199-19.2 0-10.603-8.596-19.198-19.199-19.198-10.603 0-19.199 8.595-19.199 19.198 0 10.604 8.596 19.2 19.199 19.2Z"
        />
        <path
          fill="#E33B2E"
          d="M48.501 24.252h41.997A48.494 48.494 0 0 0 48.498 0 48.486 48.486 0 0 0 6.5 24.257l21 36.371.005.003a24.248 24.248 0 0 1 20.997-36.38h-.001Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h97v97H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const MicrosoftEdgeIcon = (props: SvgProps) => {
  const { width = 94, height = 94, color = 'none' } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={color}
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          fill="url(#b)"
          d="M84.857 69.95a34.403 34.403 0 0 1-3.892 1.725 37.415 37.415 0 0 1-13.182 2.35c-17.368 0-32.496-11.934-32.496-27.282a11.566 11.566 0 0 1 6.022-10.024c-15.716.66-19.755 17.037-19.755 26.62 0 27.173 25.005 29.89 30.403 29.89 2.9 0 7.27-.845 9.914-1.69l.477-.146a47.11 47.11 0 0 0 24.455-19.388 1.47 1.47 0 0 0-1.946-2.056Z"
        />
        <path
          fill="url(#c)"
          d="M84.857 69.95a34.403 34.403 0 0 1-3.892 1.725 37.415 37.415 0 0 1-13.182 2.35c-17.368 0-32.496-11.934-32.496-27.282a11.566 11.566 0 0 1 6.022-10.024c-15.716.66-19.755 17.037-19.755 26.62 0 27.173 25.005 29.89 30.403 29.89 2.9 0 7.27-.845 9.914-1.69l.477-.146a47.11 47.11 0 0 0 24.455-19.388 1.47 1.47 0 0 0-1.946-2.056Z"
          opacity={0.35}
        />
        <path
          fill="url(#d)"
          d="M38.812 88.602a29.078 29.078 0 0 1-8.335-7.82 29.632 29.632 0 0 1 10.832-44.063c1.175-.551 3.12-1.506 5.728-1.469a11.899 11.899 0 0 1 9.437 4.773 11.713 11.713 0 0 1 2.313 6.867c0-.074 8.996-29.228-29.375-29.228-16.12 0-29.375 15.275-29.375 28.714A47.808 47.808 0 0 0 4.48 66.938 47 47 0 0 0 61.908 91.54a27.721 27.721 0 0 1-23.06-2.938h-.036Z"
        />
        <path
          fill="url(#e)"
          d="M38.812 88.602a29.078 29.078 0 0 1-8.335-7.82 29.632 29.632 0 0 1 10.832-44.063c1.175-.551 3.12-1.506 5.728-1.469a11.899 11.899 0 0 1 9.437 4.773 11.713 11.713 0 0 1 2.313 6.867c0-.074 8.996-29.228-29.375-29.228-16.12 0-29.375 15.275-29.375 28.714A47.808 47.808 0 0 0 4.48 66.938 47 47 0 0 0 61.908 91.54a27.721 27.721 0 0 1-23.06-2.938h-.036Z"
          opacity={0.41}
        />
        <path
          fill="url(#f)"
          d="M55.96 54.638c-.331.367-1.25.918-1.25 2.056 0 .954.625 1.91 1.763 2.68 5.251 3.672 15.202 3.158 15.239 3.158a21.884 21.884 0 0 0 11.126-3.048A22.546 22.546 0 0 0 94 40.06c.11-8.225-2.938-13.696-4.15-16.12C82.03 8.74 65.25 0 47 0A47 47 0 0 0 0 46.34c.184-13.403 13.512-24.235 29.375-24.235 1.285 0 8.629.11 15.422 3.672a26.658 26.658 0 0 1 11.346 10.758c2.24 3.892 2.644 8.85 2.644 10.832 0 1.983-.992 4.884-2.864 7.307l.036-.036Z"
        />
        <path
          fill="url(#g)"
          d="M55.96 54.638c-.331.367-1.25.918-1.25 2.056 0 .954.625 1.91 1.763 2.68 5.251 3.672 15.202 3.158 15.239 3.158a21.884 21.884 0 0 0 11.126-3.048A22.546 22.546 0 0 0 94 40.06c.11-8.225-2.938-13.696-4.15-16.12C82.03 8.74 65.25 0 47 0A47 47 0 0 0 0 46.34c.184-13.403 13.512-24.235 29.375-24.235 1.285 0 8.629.11 15.422 3.672a26.658 26.658 0 0 1 11.346 10.758c2.24 3.892 2.644 8.85 2.644 10.832 0 1.983-.992 4.884-2.864 7.307l.036-.036Z"
        />
      </g>
      <defs>
        <radialGradient
          id="c"
          cx={0}
          cy={0}
          r={1}
          gradientTransform="matrix(35.0297 0 0 33.2782 57.722 65.486)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.7} stopOpacity={0} />
          <stop offset={0.9} stopOpacity={0.5} />
          <stop offset={1} />
        </radialGradient>
        <radialGradient
          id="e"
          cx={0}
          cy={0}
          r={1}
          gradientTransform="rotate(-81.384 55.428 21.46) scale(52.6496 42.5356)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.8} stopOpacity={0} />
          <stop offset={0.9} stopOpacity={0.5} />
          <stop offset={1} />
        </radialGradient>
        <radialGradient
          id="f"
          cx={0}
          cy={0}
          r={1}
          gradientTransform="rotate(92.291 -3.593 13.254) scale(74.3782 158.41)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#35C1F1" />
          <stop offset={0.1} stopColor="#34C1ED" />
          <stop offset={0.2} stopColor="#2FC2DF" />
          <stop offset={0.3} stopColor="#2BC3D2" />
          <stop offset={0.7} stopColor="#36C752" />
        </radialGradient>
        <radialGradient
          id="g"
          cx={0}
          cy={0}
          r={1}
          gradientTransform="matrix(10.00364 34.2982 -27.89136 8.13498 88.147 28.384)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#66EB6E" />
          <stop offset={1} stopColor="#66EB6E" stopOpacity={0} />
        </radialGradient>
        <linearGradient
          id="b"
          x1={21.554}
          x2={87.06}
          y1={64.992}
          y2={64.992}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0C59A4" />
          <stop offset={1} stopColor="#114A8B" />
        </linearGradient>
        <linearGradient
          id="d"
          x1={56.07}
          x2={15.202}
          y1={36.572}
          y2={81.112}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1B9DE2" />
          <stop offset={0.2} stopColor="#1595DF" />
          <stop offset={0.7} stopColor="#0680D7" />
          <stop offset={1} stopColor="#0078D4" />
        </linearGradient>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h94v94H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const GreyInfoIcon = (props: SvgProps) => {
  const { width = 21, height = 21, color = '#E0E0E0' } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      {...props}
    >
      <path
        fill={color}
        d="M9.625 14.875h1.75v-5.25h-1.75v5.25ZM10.5 1.75c-4.83 0-8.75 3.92-8.75 8.75s3.92 8.75 8.75 8.75 8.75-3.92 8.75-8.75-3.92-8.75-8.75-8.75Zm0 15.75c-3.859 0-7-3.141-7-7s3.141-7 7-7 7 3.141 7 7-3.141 7-7 7Zm-.875-9.625h1.75v-1.75h-1.75v1.75Z"
      />
    </svg>
  );
};

export const LockIcon = (props: SvgProps) => {
  const { width = 21, height = 21, color = '#333333' } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      {...props}
    >
      <path
        fill={color}
        d="M10.5 5.25H9.75V3.75C9.75 1.68 8.07 0 6 0C3.93 0 2.25 1.68 2.25 3.75V5.25H1.5C0.675 5.25 0 5.925 0 6.75V14.25C0 15.075 0.675 15.75 1.5 15.75H10.5C11.325 15.75 12 15.075 12 14.25V6.75C12 5.925 11.325 5.25 10.5 5.25ZM6 12C5.175 12 4.5 11.325 4.5 10.5C4.5 9.675 5.175 9 6 9C6.825 9 7.5 9.675 7.5 10.5C7.5 11.325 6.825 12 6 12ZM8.325 5.25H3.675V3.75C3.675 2.4675 4.7175 1.425 6 1.425C7.2825 1.425 8.325 2.4675 8.325 3.75V5.25Z"
      />
    </svg>
  );
};

export const UnlockIcon = (props: SvgProps) => {
  const { width = 21, height = 21, color = '#999999' } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      {...props}
    >
      <path
        fill={color}
        d="M6 12C6.82 12 7.5 11.32 7.5 10.5C7.5 9.68 6.82 9 6 9C5.18 9 4.5 9.68 4.5 10.5C4.5 11.32 5.18 12 6 12ZM9.75 3.75C9.75 2.47 10.79 1.42 12.07 1.42C13.35 1.42 14.39 2.46 14.39 3.75H15.82C15.82 1.68 14.14 0 12.07 0C10 0 8.32 1.68 8.32 3.75V5.25H1.5C0.67 5.25 0 5.93 0 6.75V14.25C0 15.07 0.67 15.75 1.5 15.75H10.5C11.32 15.75 12 15.07 12 14.25V6.75C12 5.93 11.32 5.25 10.5 5.25H9.75V3.75ZM10.5 14.25H1.5V6.75H10.5V14.25Z"
      />
    </svg>
  );
};
