/* eslint-disable */
import Keycloak, { KeycloakConfig } from 'keycloak-js';

const keycloakInstance = new Keycloak({
  url: window?._env_?.VITE_KEYCLOAK_ENDPOINT,
  realm: window?._env_?.VITE_KEYCLOAK_REALM,
  clientId: window?._env_?.VITE_KEYCLOAK_CLIENT_ID,
} as KeycloakConfig);

export default keycloakInstance;
