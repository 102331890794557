export const GAP = {
  0.5: '4px',
  1: '8px',
  1.5: '12px',
  1.75: '14px',
  2: '16px',
  2.5: '20px',
  3: '24px',
  3.5: '28px',
  3.75: '30px',
  4: '32px',
  5: '40px',
  6: '48px',
  7: '56px',
};
