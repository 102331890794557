/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { useEffect, useState } from 'react';
import { SupplierBranding } from '@betotrace/shared/graphql/generated';
import { apiEndpoint } from '@betotrace/common/constants/apiEndpoint';

export function useSupplierBranding() {
  const [supplierBranding, setSupplierBranding] =
    useState<SupplierBranding>(null);
  const betotraceFavIcon = '/logo.png';
  const betotraceDocumentTitle = 'Betotrace';

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: `
            query supplierBranding {
              supplierBranding {
                logoUrl
                faviconUrl
                title
              }
            }
          `,
        }),
      });
      const responseData = await response.json();

      if (responseData.data && responseData.data.supplierBranding) {
        const supplierBrand = responseData.data
          .supplierBranding as SupplierBranding;
        setSupplierBranding(supplierBrand);
        const favicon = document.querySelector('link[rel="icon"]');
        favicon.href = supplierBrand.faviconUrl || betotraceFavIcon;
        document.title = supplierBrand.title || betotraceDocumentTitle;
      }
    } catch (error) {
      console.error('error when setting branding', error);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return supplierBranding;
}

export default useSupplierBranding;
