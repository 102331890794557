/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  Card,
  CardContent,
  createTheme,
  Grid,
  ThemeProvider,
} from '@mui/material';
import '../Styles.scss';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { projectInitials, statusText } from '@betotrace/shared/utils';
import CustomBadge from '../customBadge/CustomBadge';
import { Project } from '@betotrace/shared/graphql/generated';

const cardTheme = createTheme({
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: '1px solid #e5e5e5',
        },
      },
    },
  },
});

const CardComp = (props) => {
  const clickHandler = () => {
    if (props?.onClick) {
      props?.onClick(props?.header);
    }
  };

  const getInitialsClassName = () => {
    const { header, status } = props;
    if (header.length === 1) {
      return 'iconImage';
    } else {
      switch (status) {
        case 'PLANNED':
          return 'iconImagePlanned status-planned';
        case 'COMPLETED':
          return 'iconImageCompleted status-completed';
        default:
          return 'iconImageInProgress status-inProgress';
      }
    }
  };

  const renderMemberIcons = (members: Project['members'], projMem: boolean) => {
    return (
      <div>
        {members.slice(0, 2).map((value) => (
          <span key={value.userId} className="roundImage">
            {projectInitials(`${value.user.firstName} ${value.user.lastName}`)}
          </span>
        ))}
        {members.length > 2 && (
          <span key={members[2]?.userId} className="roundImage">
            +{members.length - 2}
          </span>
        )}
        {!projMem && (
          <>
            <span className="iconImageCircle"></span>
            <span className="iconImageCircle"></span>
          </>
        )}
      </div>
    );
  };

  const mainCardClassName = `cardBorderColor ${
    props?.propertyFrom && `cardBorder ${props.selProj && 'selectedProject'}`
  }`;

  return (
    <ThemeProvider theme={cardTheme}>
      <Card
        className={props.borderColor ? mainCardClassName : ''}
        sx={{ marginTop: '5px', marginBottom: '5px' }}
        id={`project-list-${props.header}`}
      >
        {props?.propertyFrom ? (
          <CardContent
            className={`text-${props?.alignType} noPadding projectCardHover`}
            onClick={clickHandler}
          >
            <Grid container>
              <Grid item md={10} sm={10} xs={10}>
                <div className="cardWidth imageDiv">
                  <span className={getInitialsClassName()}>
                    {props?.header?.match(/\b\w/g) &&
                      projectInitials(props?.header)}
                  </span>
                </div>
                <div>
                  <p>
                    <span
                      className={
                        props?.header?.length === 1
                          ? 'cardHeader'
                          : 'cardHeaderText'
                      }
                    >
                      <b>{props?.header}</b>
                    </span>
                    <br />
                    <span
                      className={
                        props?.body?.length === 1 ? 'cardDesc' : 'cardDescText'
                      }
                    >
                      {props?.body}
                    </span>
                  </p>
                </div>
              </Grid>
              <Grid item md={2} sm={2} xs={2} className="checkIcon alignRight">
                {props?.showCheck && (
                  <CheckCircleIcon className="checkedCircle" />
                )}
              </Grid>
            </Grid>
            <Grid container className="webView">
              <Grid item md={8} sm={8} xs={8} className="memberIcons">
                {renderMemberIcons(props.members, props.projMem)}
              </Grid>
              <Grid item md={4} sm={4} xs={4}>
                <span className="alignRight badgeAlignment">
                  <CustomBadge
                    status={statusText(props?.status)}
                    tooltip={false}
                  />
                </span>
              </Grid>
            </Grid>
          </CardContent>
        ) : (
          <Grid container>
            <Grid item md={12}>
              <CardContent
                className={`text-${props.alignType}`}
                onClick={clickHandler}
              >
                <h4>{props.header}</h4>
                <p>{props.body}</p>
              </CardContent>
            </Grid>
          </Grid>
        )}
      </Card>
    </ThemeProvider>
  );
};

export default CardComp;
