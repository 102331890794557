import useOrder from '@betotrace/modules/order/pages/details/Details/hooks/useOrder';
import PassivePopover from '@betotrace/modules/pages/NewStructure/components/Popover/PassivePopover';
import Popover from '@betotrace/modules/pages/NewStructure/components/Popover/Popover';
import ShareOrderPopover from '@betotrace/modules/pages/NewStructure/components/Popover/ShareOrderPopover';
import { useResponsiveContext } from '@betotrace/shared/contexts';
import PublicContext from '@betotrace/shared/contexts/publicContext/public.context';
import { KeycloakUserTypes } from '@betotrace/shared/types/user';
import { UserType } from '@betotrace/shared/utils';
import { Menu } from '@mui/icons-material';
import { AppBar, Box, Button, CssBaseline, Toolbar } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useActivePathMethod } from '../../utils/helper/helper';
import { UserMenu } from './userMenu/UserMenu';
import './NavBar.scss';
import { SupplierBranding } from '@betotrace/shared/graphql/generated';
import { useSupplierBrandingContext } from '@betotrace/shared/contexts/supplierBranding/supplierBranding.context';
import Sidebar from '@betotrace/shared/components/sidebar/Sidebar';

const drawerWidth = 257;
const mobileWidth = 991;

interface Props {
  isLoggedIn: boolean;
  user: KeycloakUserTypes;
  showTopNavbar?: boolean;
}

export default function NavBar(props: Props) {
  const { showTopNavbar, isLoggedIn, user } = props;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
  const history: any = useHistory();
  const userType = UserType();
  const location = useLocation();
  const [active, setActive] = useState(location?.pathname);
  const { publicType, popoverId, anchorEl, isOpen, handleClosePopover } =
    useContext(PublicContext);
  const { general } = useOrder();
  const responsive = useResponsiveContext();
  const [mobileOpen, setMobileOpen] = React.useState({
    isMobileOpen: false,
    headerClass: 'headerBoxWhite',
  });
  const supplierBrand: SupplierBranding = useSupplierBrandingContext();

  const activePath = useActivePathMethod(location?.pathname, userType);
  useEffect(() => {
    setActive(activePath);
  }, [location?.pathname, userType]);

  const handleMobileState = ({
    nextMobileState = false,
    checkWindowWith = false,
  }: { nextMobileState?: boolean; checkWindowWith?: boolean } = {}) => {
    const headerClass =
      mobileOpen.isMobileOpen ||
      (checkWindowWith && window.innerWidth <= mobileWidth)
        ? 'headerMobile'
        : 'headerDesktop';

    setMobileOpen({
      isMobileOpen: nextMobileState,
      headerClass: headerClass,
    });
  };
  const navHandler = (navLink: string, openNewWindow: boolean) => {
    if (openNewWindow) {
      window.open(navLink, '_blank');
    } else {
      if (window.innerWidth <= mobileWidth) {
        handleMobileState();
      }
      setActive(navLink);
      history.push(navLink);
    }
  };

  const handleDrawerToggle = () => {
    handleMobileState({ nextMobileState: !mobileOpen.isMobileOpen });
  };

  useEffect(() => {
    handleMobileState({ checkWindowWith: true });
  }, [responsive]);

  const supplierLogo = supplierBrand.logoUrl.startsWith('http')
    ? supplierBrand.logoUrl
    : `https://${supplierBrand.logoUrl}`;

  return showTopNavbar || publicType === 'privateCustomer' ? (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: `#fff !important`,
      }}
    >
      <Toolbar className="headerDesktop">
        {supplierBrand?.logoUrl && (
          <Box>
            <img src={supplierLogo} className="logoImg" alt="BetoTrace" />
          </Box>
        )}
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ marginLeft: '20px' }}>
          <UserMenu isLoggedIn={isLoggedIn} user={user} />
        </Box>
      </Toolbar>
    </AppBar>
  ) : (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { lg: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            backgroundColor: `#fff !important`,
          }}
        >
          <Toolbar
            className={
              !mobileOpen.isMobileOpen
                ? mobileOpen.headerClass
                : 'headerDesktop'
            }
          >
            {!mobileOpen.isMobileOpen && window.innerWidth <= mobileWidth && (
              <Button onClick={handleDrawerToggle} size="small">
                <Menu />
              </Button>
            )}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ marginLeft: '20px' }}>
              <UserMenu isLoggedIn={isLoggedIn} user={user} />
            </Box>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{
            width: { sm: drawerWidth },
            flexShrink: { sm: 0 },
          }}
          aria-label="NavBAr"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Sidebar
            active={active}
            navHandler={navHandler}
            open={mobileOpen.isMobileOpen}
            onClose={handleDrawerToggle}
          />
        </Box>
      </Box>
      {general?.showPopover && (
        <Popover
          style={{ pointerEvents: 'none' }}
          id={popoverId}
          anchorEl={anchorEl}
          isOpen={isOpen && ['navList-id', 'navBar-id'].includes(popoverId)}
          handleClose={handleClosePopover}
        >
          {publicType === 'shared' ? <ShareOrderPopover /> : <PassivePopover />}
        </Popover>
      )}
    </>
  );
}
