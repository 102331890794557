import { useAuthContext } from '@betotrace/shared/contexts';
import { UserTypes } from '../../types/user';

const UserType = (): string => {
  const userData = useAuthContext();
  const user = userData?.user?.business_type;
  return user?.toLowerCase() as UserTypes;
};

export default UserType;
