import { IntlShape } from 'react-intl';
import {
  DateShortFormat,
  DateShortFormatWithTime,
} from '../dateFormater/DateFormater';

import {
  Order,
  OrderConfirmationQuantityUnit,
  OrderDetailDto,
  OrderDetailPace,
  OrderDetailPaceCustomSchedule,
  OrderDetailPaceType,
  OrderDetailValue,
  OrderQuantityUnit,
  OrderTransportDateType,
  OrderTransportType,
  ProductVersionSettingsQuantityUnit,
  ProjectProductQuantityUnit,
} from '@betotrace/shared/graphql/generated';
import { globalIntl } from '@betotrace/shared/contexts/responsiveContext/responsive.context';
import { capitalizeName } from '@betotrace/modules/pages/NewStructure/utils/utils';

export const checkProductQuantityUnit = (
  unit:
    | OrderQuantityUnit
    | ProductVersionSettingsQuantityUnit
    | ProjectProductQuantityUnit
    | OrderConfirmationQuantityUnit,
  perHour?: boolean
): string => {
  switch (unit) {
    case OrderQuantityUnit.Ton:
      return globalIntl.formatMessage({ id: 'orderList.column.quantity.unit' });

    case OrderQuantityUnit.Piece:
      return globalIntl.formatMessage({ id: 'quantity.unit.piece' });

    case OrderConfirmationQuantityUnit.Flatrate:
      return globalIntl.formatMessage({ id: 'quantity.unit.flatrate' });

    case OrderConfirmationQuantityUnit.Kilogram:
      return globalIntl.formatMessage({ id: 'quantity.unit.kilogram' });

    case OrderConfirmationQuantityUnit.Liter:
      return globalIntl.formatMessage({ id: 'quantity.unit.liter' });

    case OrderConfirmationQuantityUnit.Meter:
      return globalIntl.formatMessage({ id: 'quantity.unit.meter' });

    default:
      return perHour
        ? `m³/${globalIntl.formatMessage({ id: 'hour.title' })}`
        : 'm³';
  }
};

export const checkIfSaldoShouldShow = (
  details?: OrderDetailDto[],
  intl?: IntlShape
): string | null => {
  const saldoValue: OrderDetailValue = details?.find(
    (detail: OrderDetailDto) => detail.component.key === 'transport.saldo'
  );
  return saldoValue?.value
    ? `+ ${intl.formatMessage({ id: 'pdf.saldo', defaultMessage: 'Saldo' })}`
    : null;
};

export const getPace = (order: Order): string => {
  const paceDetail: OrderDetailPace = order?.details?.find(
    (detail: OrderDetailPace) => detail.component.key === 'transport.pace'
  );

  if (!paceDetail) {
    return '';
  }

  switch (paceDetail.pace.type) {
    case OrderDetailPaceType.Standard:
      return 'standard';
    case OrderDetailPaceType.Fixed:
      const unit = checkProductQuantityUnit(order.quantity.unit, true);
      return `${paceDetail.pace.fixedQuantity} ${unit}`;
    case OrderDetailPaceType.Custom:
      return globalIntl.formatMessage({ id: 'pace.type.custom' });
    default:
      return 'N/A';
  }
};

export const getDateFormatByDateType = (
  date: string,
  dateType: OrderTransportDateType,
  locale: string
) => {
  return dateType === OrderTransportDateType.DateAndTime
    ? DateShortFormatWithTime(date, false, locale)
    : DateShortFormat(date, locale);
};

export const showCustomPaceValues = (
  list: OrderDetailPaceCustomSchedule[],
  intl: IntlShape,
  quantityUnit: string
) => {
  const formattedValues = list.map((c, index) => {
    const time = new Date(c.time as Date);
    const hours = ('0' + time.getUTCHours()).slice(-2);
    const minutes = ('0' + time.getUTCMinutes()).slice(-2);

    const customPaceType =
      index === 0
        ? capitalizeName(
            intl.formatMessage({
              id: 'pace.type.custom',
              defaultMessage: 'Custom',
            })
          )
        : '';

    return `${customPaceType}\n${hours}:${minutes} - ${c.quantity} ${quantityUnit}\n`;
  });

  return formattedValues.join('\r\n');
};
export const getTransportTypeName = (
  type: OrderTransportType,
  intl: IntlShape
) => {
  switch (type) {
    case OrderTransportType.DepositAtSupplier:
      return intl.formatMessage({
        id: 'transport.type.depositAtSupplier',
        defaultMessage: 'Deposit at Supplier',
      });
    case OrderTransportType.PickUpByCustomer:
      return intl.formatMessage({
        id: 'transport.type.pickupByCustomer',
        defaultMessage: 'Pickup by Customer',
      });
    case OrderTransportType.PickUpBySupplier:
      return intl.formatMessage({
        id: 'transport.type.pickupBySupplier',
        defaultMessage: 'Pickup By Supplier',
      });
    default:
      return intl.formatMessage({
        id: 'transport.type.deliveryBySupplier',
        defaultMessage: 'Delivery by Supplier',
      });
  }
};
