import React, { useEffect } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Alert, Button, Collapse, IconButton, Theme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import '../Styles.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  })
);

const Notifications = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  useEffect(() => {
    setTimeout(() => {
      setOpen(false);
    }, 5000); // miliseconds
    return () => {
      setOpen(false);
    };
  }, []);

  const handleClick = () => {
    window.location.href = '#';
  };
  return (
    <div className={classes.root}>
      <Collapse in={open}>
        <Alert
          icon={false}
          severity={props.severity as string}
          action={
            props.closeBtnVisible === 'true' && (
              <>
                {props.viewBtnVisible === 'true' && (
                  <Button onClick={handleClick} color="inherit" size="small">
                    VIEW
                  </Button>
                )}
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              </>
            )
          }
        >
          <div
            className={`notificationText ${
              props.severity === 'success'
                ? 'success'
                : props.severity === 'error'
                ? 'error'
                : 'warning'
            }`}
          >
            {props.message}
            <div className="notificationMsg">{props.secondMsg}</div>
          </div>
        </Alert>
      </Collapse>
    </div>
  );
};
export default Notifications;
