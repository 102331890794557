import { GreyInfoIcon } from '@betotrace/assets/svg';
import useOrder from '@betotrace/modules/order/pages/details/Details/hooks/useOrder';
import { useResponsiveContext } from '@betotrace/shared/contexts';
import {
  IconButton,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from '@mui/material';
import { useState } from 'react';
import './CustomBadge.scss';
import { statuses } from './utils/statuses';

interface CustomBadgeProps {
  status?: string;
  tooltip?: boolean;
  active?: boolean;
  onClick?: () => void;
  domID?: string;
}

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    color: '#9C9C9C',
    boxShadow: `0px 0px 25px rgba(0, 0, 0, 0.1)`,
    fontSize: '14px',
    maxWidth: '200px',
    textAlign: 'center',
    padding: '10px',
  },
}));

const CustomBadge = ({
  status,
  tooltip = false,
  onClick,
  active = false,
  domID,
}: CustomBadgeProps) => {
  const { isMobile, isTablet } = useResponsiveContext();
  const userType = useOrder();
  const shortStatus = status.replace(/\s/g, '').toLowerCase();
  const badgeInfo = statuses?.[shortStatus]?.[userType?.key];
  const [showTooltip, setShowTooltip] = useState(false);

  const handleInfoClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setShowTooltip(true);

    const showTooltipHandler = setTimeout(() => {
      setShowTooltip(false);
    }, 3000);

    return () => {
      clearTimeout(showTooltipHandler);
    };
  };

  if (!badgeInfo) {
    return null;
  }

  if (isMobile || isTablet) {
    return (
      <div
        className="mobile-custom-badge-container"
        onClick={onClick}
        id={domID}
      >
        <div
          className={`mobile-custom-badge ${badgeInfo?.className} ${
            active ? 'disabled' : ''
          }`}
        >
          <p className="mobile-custom-badge-text">{badgeInfo?.title}</p>
        </div>
        {tooltip && (
          <CustomTooltip title={badgeInfo?.subtitle} open={showTooltip}>
            <IconButton sx={{ padding: 0 }} onClick={handleInfoClick}>
              <GreyInfoIcon />
            </IconButton>
          </CustomTooltip>
        )}
      </div>
    );
  }

  return tooltip ? (
    <CustomTooltip title={badgeInfo?.subtitle}>
      <div
        className={`custom-badge ${badgeInfo?.className} ${
          active ? 'disabled' : ''
        }`}
        id={domID}
      >
        <p className="custom-badge-text">{badgeInfo?.title}</p>
      </div>
    </CustomTooltip>
  ) : (
    <div
      className={`custom-badge ${badgeInfo?.className} ${
        active ? 'disabled' : ''
      }`}
      onClick={onClick}
      id={domID}
    >
      <p className="custom-badge-text">{badgeInfo?.title}</p>
    </div>
  );
};

export default CustomBadge;
