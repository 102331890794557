import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

function LocationLogger() : any {
  const location = useLocation();

  useEffect(() => {
    const currentUrl = location.pathname + location.search + location.hash;
    
    // Log to console
    // console.log('URL changed to:', currentUrl);

    // Send pageview to Google Analytics
    ReactGA.send({ hitType: 'pageview', page: currentUrl });
  }, [location]); // Trigger whenever the location object changes

  return null; // No UI rendering needed
}

export default LocationLogger;
