import { useState } from 'react';
import { Avatar, IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './LanguageSelector.scss';
import { useAuthContext } from '@betotrace/shared/contexts';
import { useUpdateUserProfileMutation } from '@betotrace/shared/graphql/generated';
import { useHistory } from 'react-router-dom';
import usePublicPath from '@betotrace/shared/hooks/usePublicPath/usePublicPath';
import { languages } from './helper/helper';
import LanguageList from './languageList/LanguageList';

interface LanguageSelectorProps {
  hideText?: boolean;
  whiteIcon?: boolean;
}

const avatarSx = {
  width: 28,
  height: 25,
  borderRadius: 2,
  backgroundColor: 'transparent',
};

const buttonSx = {
  ml: '5px',
  '&:hover': {
    backgroundColor: '#fbfbfb',
    borderRadius: 2,
  },
};
const LanguageSelector = ({ hideText, whiteIcon }: LanguageSelectorProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unused-vars
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const isPublicPath = usePublicPath();
  const userData = useAuthContext();

  const [updateUserProfileMutation] = useUpdateUserProfileMutation();

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setIsOpen(false);
  };

  const handleLanguageSelect = async (localeId: string) => {
    if (userData?.isLoggedIn) {
      await updateUserProfileMutation({
        variables: {
          localeId,
        },
      });
    } else if (isPublicPath) {
      history.push(`${history.location.pathname}?locale=${localeId}`);
    }
    window.location.reload();
  };
  const lang = languages?.find((lang) => lang?.short === userData?.locale);

  return (
    <div
      className="languageSelector"
      onClick={anchorEl === null ? handleClick : handleClose}
    >
      {!hideText && (
        <div className="languageSelector__language">{lang?.name}</div>
      )}
      <IconButton
        size="small"
        disableRipple
        sx={buttonSx}
        aria-controls={isOpen ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
      >
        <Avatar sx={avatarSx}>{lang?.flag}</Avatar>
        <KeyboardArrowDownIcon sx={{ fill: whiteIcon ? '#fff' : 'initial' }} />
      </IconButton>
      {isOpen && (
        <LanguageList
          anchorEl={anchorEl}
          handleClose={handleClose}
          isOpen={isOpen}
          handleSelect={handleLanguageSelect}
        />
      )}
    </div>
  );
};

export default LanguageSelector;
