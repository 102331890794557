/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import {
  Order,
  OrderDetailsDocument,
  SortBy,
  SupplierSettingsQuery,
  useDeliveriesQuery,
  useSupplierSettingsQuery,
} from '@betotrace/shared/graphql/generated';
import { LoaderWithOverlay } from '@betotrace/shared/components';
import useOrder, {
  OrderTypes,
} from '../../pages/details/Details/hooks/useOrder';
import { UserType } from '@betotrace/shared/utils';
import usePublicPath from '@betotrace/shared/hooks/usePublicPath/usePublicPath';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { updatedOrderVar } from '@betotrace/App';

interface OrderDetailsContextProps {
  orderData: Order;
  setUpdatedOrder: (_item: Order) => void;
  handleRefetchOrders: () => void;
  setShouldRedirect: (_bool: boolean) => void;
  shouldRedirect: boolean;
  deliveryCount: null | number;
  supplierSettings: null | SupplierSettingsQuery['supplierSettings'];
}

const OrderDetailsContext = React.createContext<OrderDetailsContextProps>({
  orderData: undefined,
  setUpdatedOrder: (_item: Order) => {},
  handleRefetchOrders: () => {},
  setShouldRedirect: (_bool: boolean) => {},
  shouldRedirect: false,
  deliveryCount: null,
  supplierSettings: null,
});
export const OrderDetailsContextProvider = (props: {
  children: JSX.Element;
}) => {
  const updatedOrderData = useReactiveVar(updatedOrderVar);
  const [orderData, setOrderData] = useState<Order>(null);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [deliveryCount, setDeliveryCount] = useState<null | number>(null);
  const [supplierSettings, setSupplierSettings] = useState<
    SupplierSettingsQuery['supplierSettings'] | null
  >(null);

  
  const userType = UserType();
  const isPublicPath = usePublicPath();

  const params: Readonly<Partial<{ id: string; userType: OrderTypes }>> =
    useParams<{ id: string; userType: OrderTypes }>();

  const getDetailsData = useOrder();

  const checkIfShouldRedirect = (data?: Order) => {
    if (!data || (!userType && !isPublicPath)) {
      setShouldRedirect(true);
    }
  };

  const setUpdatedOrder = (item: Order) => {
    setOrderData(item);
  };
  const [order, { loading, refetch }] = useLazyQuery(OrderDetailsDocument, {
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      setOrderData(data?.order as Order);
      checkIfShouldRedirect(data?.order as Order);
    },
  });

  //deliveries
  useDeliveriesQuery({
    skip: !orderData?.id,
    variables: {
      filter: {
        orderId: params?.id ?? orderData?.id,
      },
      page: {
        size: 200,
      },
      sort: {
        createdAt: SortBy.Desc,
      },
    },
    onCompleted(data) {
      if (data.deliveries) {
        setDeliveryCount(data.deliveries.pageInfo.totalItems);
      }
    },
  });

  const handleRefetchOrders = () => {
    refetch({
      filter: {
        orderId: params?.id,
      },
    }).then(({ data }) => {
      setOrderData(data?.order as Order);
      checkIfShouldRedirect(data?.order as Order);
    });
  };

  const filter = useMemo(() => {
    return { supplierId: orderData?.supplierBranch?.supplierId };
  }, [orderData?.supplierBranch?.supplierId]);

  const { loading: supplierSettingsLoading } = useSupplierSettingsQuery({
    variables: { filter },
    skip: !orderData?.supplierBranch?.supplierId,
    onCompleted: (data) => {
      setSupplierSettings(data?.supplierSettings);
    },
  });

  useEffect(() => {
    if (params?.id) {
      order({
        variables: {
          filter: {
            orderId: params?.id,
          },
        },
      });
    }
  }, [params?.id]);

  useEffect(() => {
    if (updatedOrderData && orderData && params?.id === updatedOrderData.id) {
      const clonedOrderDetails = {
        ...orderData,
        quantity: {
          ...orderData?.quantity,
          ...updatedOrderData?.quantity,
        },
        status: updatedOrderData?.status,
        transport: {
          ...orderData.transport,
          date: updatedOrderData?.transport?.date,
        },
      };
      setOrderData(clonedOrderDetails);
    }
  }, [updatedOrderData]);

  if (loading || supplierSettingsLoading) {
    return <LoaderWithOverlay />;
  }

  if (shouldRedirect) {
    if (!getDetailsData.shouldBeAuth) {
      return (
        <Redirect
          to={{
            pathname: '/login',
          }}
        />
      );
    }

    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    );
  }

  return (
    <OrderDetailsContext.Provider
      value={{
        orderData,
        setUpdatedOrder,
        handleRefetchOrders,
        setShouldRedirect,
        shouldRedirect,
        deliveryCount,
        supplierSettings,
      }}
    >
      {props.children}
    </OrderDetailsContext.Provider>
  );
};

export default OrderDetailsContext;
