import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMediaQuery } from 'react-responsive';
import { IntlShape, useIntl } from 'react-intl';

type Props = {
  children: React.ReactNode;
};

interface ResponsiveContextType {
  breakpoint: {
    isMobile: boolean;
    isTablet: boolean;
    isDesktop: boolean;
  };
  setBreakpoint: React.Dispatch<
    React.SetStateAction<{
      isMobile: boolean;
      isTablet: boolean;
      isDesktop: boolean;
    }>
  >;
}

const ResponsiveContext = createContext<ResponsiveContextType>({
  breakpoint: {
    isMobile: false,
    isTablet: false,
    isDesktop: true,
  },
  setBreakpoint: () => {},
});
export let globalIntl: IntlShape = null;

const ResponsiveProvider = ({ children }: Props) => {
  const isDesktop = useMediaQuery({ minWidth: 901 });
  const isTablet = useMediaQuery({
    minWidth: 600,
    maxWidth: 900,
  });
  const isMobile = useMediaQuery({ maxWidth: 599 });
  const firstLoad = useRef(true);
  const [breakpoint, setBreakpoint] = useState({
    isMobile,
    isTablet,
    isDesktop,
  });
  const intl = useIntl();
  globalIntl = intl;

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
      return;
    }
    setBreakpoint({
      isMobile,
      isTablet,
      isDesktop,
    });
  }, [isDesktop, isTablet, isMobile]);

  const contextValue = useMemo(
    () => ({
      breakpoint,
      setBreakpoint,
    }),
    [breakpoint, setBreakpoint]
  );

  return (
    <ResponsiveContext.Provider value={contextValue}>
      {children}
    </ResponsiveContext.Provider>
  );
};
export default ResponsiveProvider;
export const useResponsiveContext = () => {
  const { breakpoint } = useContext(ResponsiveContext);
  return breakpoint;
};

export const useResponsiveContextDispatch = () => {
  const { setBreakpoint } = useContext(ResponsiveContext);
  return setBreakpoint;
};
