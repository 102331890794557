import { ProjectAddress } from '@betotrace/common';
import {
  ProjectDeliveryAddressAddress,
  OrderDetailAddressAddress,
  VerifyVatResponseAddressDto,
} from '@betotrace/shared/graphql/generated';

export const getInitials = (name: string) => {
  if (!name) return '';
  const allNames = name.trim().split(' ');
  return allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      acc = `${acc}${curr.charAt(0).toUpperCase()}`;
    }
    return acc;
  }, '');
};

export const getInitialsRegex = (name: string): string => {
  return name
    .match(/\b(\w)/g)
    .join('')
    .substring(0, 2);
};

export const capitalizeName = (name: string): string => {
  return name?.charAt(0)?.toUpperCase() + name?.slice(1)?.toLowerCase();
};

export const formatAddress = (
  address:
    | ProjectAddress
    | ProjectDeliveryAddressAddress
    | OrderDetailAddressAddress
    | VerifyVatResponseAddressDto
) => {
  return !!address
    ? `${address.street}, ${address.zipCode} ${address.city}, ${address?.countryCode}`
    : '';
};

export const groupBy = <T, K extends keyof unknown>(
  arr: T[],
  key: (i: T) => K
) =>
  arr?.reduce((groups, item) => {
    (groups[key(item)] ||= []).push(item);
    return groups;
  }, {} as Record<K, T[]>);

export const generateUid = () => {
  return Date.now().toString(36) + Math.random().toString(36).substring(2);
};

export const getSearchQueries = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams;
};
