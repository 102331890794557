import Gleap from 'gleap';

export interface GleapIdentityConfig {
  userId: string;
  name: string;
  email: string;
  local: string;
  hash: string;
}

export function setGleapIdentity(options: GleapIdentityConfig) {
  Gleap.identify(
    options.userId,
    {
      name: options.name,
      email: options.email,
    },
    options.hash
  );

  Gleap.setLanguage(options.local);
}

export function gleapInitialize(apiKey: string) {
  Gleap.initialize(apiKey);
}

export function gleapLogout() {
  Gleap.clearIdentity();
}
