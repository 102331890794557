import ShareOrderContext from '@betotrace/modules/order/contexts/shareOrderContext/shareOrder.context';
import { resourceAccessPermission } from '@betotrace/shared/types/resouceAccessPermissions';
import { Permissions } from '@betotrace/shared/utils';
import { useContext } from 'react';
import { IUserOrder } from '../types/useOrderTypes';
import { OrderTypes } from './useOrder';
import { OrderDetailsContext } from '@betotrace/modules/order/contexts';
import { OrderSource } from '@betotrace/shared/graphql/generated';
import { useResponsiveContext } from '@betotrace/shared/contexts';

const useSupplierOrder = () => {
  const sharedOrderContext = useContext(ShareOrderContext);
  const orderContext = useContext(OrderDetailsContext);
  const { isMobile } = useResponsiveContext();
  const orderPermission = Permissions({
    key: resourceAccessPermission.supplier_order_manage,
  });

  const data: IUserOrder = {
    key: OrderTypes.supplier,
    shouldBeAuth: true,
    general: {
      showBackButton: true,
      newOrderButton: {
        show: false,
        disabled: false,
      },
      showActionBar: true,
      showOrderStatus: orderPermission,
      showNavbar: true,
      showTopNavbar: false,
      showProjectDetailDrawer: true,
      showPopover: false,
    },
    orderStatus: {
      showButtons: true,
    },
    supplierSettings: {
      checkEditExternalOrder: true,
    },
    actionBar: {
      types: {
        reorder: {
          show: false,
          disabled: false,
        },
        favorite: {
          show: false,
          disabled: false,
        },
        print: {
          show: isMobile
            ? false
            : orderContext?.orderData?.source === OrderSource.Internal,
          disabled: false,
        },
        share: {
          show: true,
          disabled: false,
          isActive: sharedOrderContext.sharedOrderList.length > 0,
        },
      },
    },
    orderDetailsInfoRight: {
      show: true,
    },
    tabs: {
      productDetails: {
        hide: false,
        disabled: false,
      },
      deliveries: {
        hide: false,
        disabled: false,
      },
      manualDeliveries: {
        hide: true,
        disabled: false,
      },
      orderHistory: {
        hide: false,
        disabled: false,
      },
      statusReport: {
        hide: false,
        disabled: false,
      },
      documents: {
        hide: false,
        disabled: false,
      },
    },
    userMenu: {
      show: true,
      disabled: false,
    },
  };

  return data;
};

export default useSupplierOrder;
