import {
  OrderSharesUserQuery,
  SortBy,
  useOrderSharesUserQuery,
} from '@betotrace/shared/graphql/generated';
import React, { SetStateAction, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import useOrder from '../../pages/details/Details/hooks/useOrder';

const ShareOrderContext = React.createContext({
  sharedOrderList: [] as OrderSharesUserQuery['orderSharesUser']['items'],
  setSharedOrderList: (
    _item: SetStateAction<OrderSharesUserQuery['orderSharesUser']['items']>
  ) => {},
});

export const ShareOrderContextProvider = (props: { children: JSX.Element }) => {
  const params: Readonly<Partial<{ id: string }>> = useParams<{ id: string }>();

  const [sharedOrderList, setSharedOrderList] = useState<
    OrderSharesUserQuery['orderSharesUser']['items']
  >([]);

  const {
    actionBar: {
      types: { share },
    },
  } = useOrder();

  useOrderSharesUserQuery({
    skip: !params?.id || share.disabled || !share.show,
    variables: {
      filter: {
        orderId: params?.id,
      },
      page: {
        size: 100,
        index: 0,
      },
      sort: {
        createdDate: SortBy.Desc
      }
    },
    onCompleted(data) {
      setSharedOrderList(data.orderSharesUser?.items ?? []);
    },
  });

  const ctxValues = useMemo(() => {
    return {
      sharedOrderList,
      setSharedOrderList,
    };
  }, [sharedOrderList]);

  return (
    <ShareOrderContext.Provider value={ctxValues}>
      {props.children}
    </ShareOrderContext.Provider>
  );
};

export default ShareOrderContext;
