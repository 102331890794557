import styled from 'styled-components';
import { Card } from '@mui/material';
import { FONT, MARGIN } from '@betotrace/shared/libs/ui-elements';

export const CardTitle = styled.div`
  font-weight: 500;
  font-size: ${(props) =>
    props.isBigContent ? FONT.SIZE.H4 : FONT.SIZE.NORMAL};
  line-height: 21px;
  color: black;
  margin-bottom: ${MARGIN.XS};
`;

export const CardDescription = styled.div`
  font-size: ${(props) =>
    props.isBigContent ? FONT.SIZE.NORMAL : FONT.SIZE.SMALL};
`;

export const CustomCard = styled(Card)({
  height: '100%',
});
