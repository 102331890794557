import { useAuthContext } from '@betotrace/shared/contexts';
import { Menu, MenuItem } from '@mui/material';
import { languages } from '../helper/helper';

interface LanguageListProps {
  anchorEl: Element | null;
  handleClose: () => void;
  handleSelect: (localeId: string) => void;
  isOpen: boolean;
}

const menuItemSx = {
  padding: '8px 15px !important',
  justifyContent: 'flex-end !important',
  fontSize: 15,
  color: '#9E9E9E',
};

const LanguageList = ({
  anchorEl,
  handleClose,
  isOpen,
  handleSelect,
}: LanguageListProps) => {
  const userData = useAuthContext();

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={isOpen}
      onClose={handleClose}
      disableScrollLock
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          maxWidth: 300,
          borderRadius: '10px',
          '& .MuiAvatar-root': {
            width: 35,
            height: 25,
            borderRadius: 2,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 35,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
          '& .MuiList-root': {
            '& :not(:last-child)': {
              marginBottom: '2px',
            },
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {languages.map((language) => {
        return (
          <MenuItem
            key={language.name}
            onClick={() => handleSelect(language.short)}
            sx={{
              ...menuItemSx,
              bgcolor:
                userData?.locale === language.short
                  ? 'rgba(0, 0, 0, 0.04)'
                  : 'transparent',
            }}
          >
            <span style={{ marginRight: 15 }}>{language.name}</span>
            {language.flag}
          </MenuItem>
        );
      })}
    </Menu>
  );
};

export default LanguageList;
