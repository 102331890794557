import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

export const useStylesTimePicker = makeStyles((_theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    overrides: {
      MuiInputBase: {
        root: {
          color: 'white !important',
          fontSize: '16px !important',
        },
      },
    },
  })
);

export const useStylesChips = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
  })
);
