/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import locale from 'date-fns/locale/en-US';
import { FormControl, ThemeProvider } from '@mui/material';
import { useIntl } from 'react-intl';

import TextField from '@mui/material/TextField';
import { DatePicker as KeyboardDatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { statusBoxInputsTheme } from '@betotrace/modules/order/pages/details/OrderStatus/theme/theme';
import useDateLocale from '@betotrace/shared/hooks/useDateLocale/useDateLocale';

if (locale && locale.options) {
  locale.options.weekStartsOn = 1;
}

export default function DatePicker(props) {
  const intl = useIntl();
  const localeValue = useDateLocale();

  return (
    <FormControl error={!!props.error}>
      <ThemeProvider theme={statusBoxInputsTheme(props?.calledBy as string)}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={localeValue}
        >
          <KeyboardDatePicker
            className="datePickerClass"
            showToolbar={false}
            inputFormat="dd/MM/yyyy"
            label={props.label}
            value={props.selectedDate}
            onChange={props.onChange}
            disablePast={props?.disablePast ?? true}
            disableFuture={props?.disableFuture ?? false}
            OpenPickerButtonProps={{ 'aria-label': 'change date' }}
            renderInput={(params) => (
              <TextField
                {...params}
                id={props.id}
                variant="filled"
                error={props.error?.key === 'date'}
                helperText={
                  props.error?.key === 'date'
                    ? intl.formatMessage({
                        id: props.error?.message,
                        defaultMessage: 'Invalid Date',
                      })
                    : ''
                }
              />
            )}
          />
        </LocalizationProvider>
      </ThemeProvider>
    </FormControl>
  );
}
