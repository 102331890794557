import { getLang } from '../getLang/getLang';

export const DateShortFormat = (date: string, locale: string) => {
  const dateLang = getLang(locale);
  const currentDate = new Date(date);
  const currentMonth = currentDate.toLocaleString(dateLang, {
    month: 'short',
  });
  const formattedDate = currentDate.getDate().toString().padStart(2, '0');
  const fullDate = `${formattedDate} ${currentMonth}`;

  return fullDate;
};

export const DateShortFormatWithTime = (
  date: string,
  showAMPM: boolean,
  locale: string
) => {
  // returns 10:30 Oct 06(HH:MM Mon dd) format
  if (showAMPM) {
    return `${DateShortFormat(date, locale)}, ${TimeFormatter(date, showAMPM)}`;
  }
  return `${DateShortFormat(date, locale)}, ${TimeFormatter(date, showAMPM)}`;
};

export const TimeFormatter = (date: string, showAMPM = false) => {
  // returns HH:MM format
  const hours =
    new Date(date).getHours() < 10
      ? `0${new Date(date).getHours()}`
      : new Date(date).getHours();
  const minutes =
    new Date(date).getMinutes() < 10
      ? `0${new Date(date).getMinutes()}`
      : new Date(date).getMinutes();
  if (showAMPM) {
    const ampm = hours >= 12 ? 'PM' : 'AM';
    return `${hours}:${minutes} ${ampm}`;
  }
  return `${hours}:${minutes}`;
};

export const GetDateFromTimeString = (timeStr: string): Date => {
  const date = new Date();
  if (timeStr) {
    date?.setHours(+timeStr.split(':')[0], +timeStr.split(':')[1]);
  }
  return date;
};

export const GetStartOfDate = (date: Date): Date => {
  date?.setHours(0, 0, 0, 0);
  return date;
};

export const DateTimeWithYearFormatter = (datetime: string) => {
  const date = new Date(datetime);
  const formattedDate = date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  const formattedTime = date.toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
  });

  return `${formattedDate} - ${formattedTime}`;
};
