import React, { Dispatch, MouseEvent, SetStateAction, useState } from 'react';
export type PublicType = 'shared' | 'passiveSupplier' | 'privateCustomer';
interface PublicContextTypes {
  isOpen: boolean;
  handleClosePopover: () => void;
  handleSetPopover: (event: HTMLElement, popoverId: string) => void;
  setAnchorEl: Dispatch<SetStateAction<HTMLElement>>;
  anchorEl: HTMLElement;
  popoverId: string;
  isPublic: boolean;
  setIsPublic: (value: boolean) => void;
  setPublicType: (value: PublicType) => void;
  publicType: PublicType;
}

const PublicContext = React.createContext<PublicContextTypes>({
  isOpen: false,
  handleClosePopover: () => {},
  handleSetPopover: (_event: HTMLElement, _popoverId: string) => {},
  setAnchorEl: () => {},
  anchorEl: null,
  popoverId: undefined,
  isPublic: false,
  setIsPublic: (_value: boolean) => undefined,
  setPublicType: (_value: PublicType) => undefined,
  publicType: 'passiveSupplier',
});

export const PublicContextProvider = (props: { children: JSX.Element }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const [popoverId, setPopoverId] = useState<string | undefined>(undefined);
  const [isPublic, setIsPublic] = useState(true);
  const [publicType, setPublicType] = useState<PublicType>('passiveSupplier');

  const isOpen = Boolean(anchorEl);

  const handleClosePopover = () => {
    setPopoverId(undefined);
    setAnchorEl(null);
  };

  const handleSetPopover = (event: HTMLElement, popoverId: string) => {
    setPopoverId(popoverId);

    const target = (event as unknown as MouseEvent<HTMLDivElement>)
      .currentTarget;
    setAnchorEl(target);
  };

  return (
    <PublicContext.Provider
      value={{
        isOpen,
        handleClosePopover,
        handleSetPopover,
        setAnchorEl,
        anchorEl,
        popoverId,
        isPublic,
        setIsPublic,
        publicType,
        setPublicType,
      }}
    >
      {props.children}
    </PublicContext.Provider>
  );
};

export default PublicContext;
