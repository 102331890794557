import {
  ManualDeliveriesDocument,
  ManualDeliveriesQuery,
  ManualDelivery,
  ManualDeliveryTypeRequest,
  SortBy,
  // useManualDeliveriesQuery,
} from '@betotrace/shared/graphql/generated';
import { UserType } from '@betotrace/shared/utils';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { updatedOrderVar } from '@betotrace/App';

const ManualDeliveryContext = React.createContext({
  modalName: '',
  setModalName: (_modalName: ManualDeliveryTypeRequest | '') => {},
  setDrawerOpen: (_value: boolean) => {},
  handleRefetchQuery: () => {},
  handleDrawerClose: () => {},
  setSelectedDelivery: (_delivery: ManualDelivery | null) => {},
  searchHandler: (
    _e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {},
  searchTerm: '',
  drawerOpen: false,
  manualDeliveries: [] as ManualDeliveriesQuery['manualDeliveries']['items'],
  selectedDelivery: null as ManualDelivery,
  getMoreManualDeliveryData: () => {},
  setOpenSnackBar: (_value: string) => {},
  openSnackBar: '',
  manualDeliveriesPagination: null,
});

export const ManualDeliveryContextProvider = (props: {
  children: JSX.Element;
}) => {
  const params: { id: string } = useParams();
  const user = UserType();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const updatedOrderData = useReactiveVar(updatedOrderVar);

  const [openSnackBar, setOpenSnackBar] = useState<string>('');
  const [modalName, setModalName] = useState<ManualDeliveryTypeRequest | ''>('');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [manualDeliveriesData, setManualDeliveries] = useState<
    ManualDeliveriesQuery['manualDeliveries']['items']
  >([]);
  const [manualDeliveriesPagination, setManualDeliveriesPagination] =
    useState<ManualDeliveriesQuery['manualDeliveries']['pageInfo']>(null);
  const [selectedDelivery, setSelectedDelivery] =
    useState<ManualDelivery | null>(null);

  const [manualDeliveries, { refetch: refetchManualDeliveries }] = useLazyQuery(
    ManualDeliveriesDocument,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        filter: {
          orderId: params.id,
        },
        page: {
          size: 20,
          index: 0
        },
        sort: {
          dateCreated: SortBy.Desc
        }
      },
      onCompleted(data) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setManualDeliveries(data?.manualDeliveries?.items);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setManualDeliveriesPagination(data?.manualDeliveries?.pageInfo);
      },
    }
  );

  useEffect(() => {
    if (params.id && user === 'customer') {
      manualDeliveries();
    }
  }, [updatedOrderData]);

  const handleRefetchQuery = React.useCallback(() => {
    refetchManualDeliveries();
  }, [refetchManualDeliveries]);

  const getMoreManualDeliveryData = React.useCallback(() => {
    refetchManualDeliveries({
      filter: {
        orderId: params.id,
      },
      page: {
        size: manualDeliveries?.length + 20,
      },
    });
  }, [refetchManualDeliveries]);

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setSelectedDelivery(null);
  };

  const searchHandler = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchTerm(e.target.value);
  };

  const ctxValues = React.useMemo(() => {
    return {
      modalName,
      setModalName,
      handleRefetchQuery,
      handleDrawerClose,
      searchHandler,
      searchTerm,
      drawerOpen,
      manualDeliveries: manualDeliveriesData,
      selectedDelivery,
      setSelectedDelivery,
      setDrawerOpen,
      setOpenSnackBar,
      openSnackBar,
      getMoreManualDeliveryData,
      manualDeliveriesPagination,
    };
  }, [
    drawerOpen,
    handleRefetchQuery,
    manualDeliveriesData,
    modalName,
    searchTerm,
    selectedDelivery,
    setOpenSnackBar,
    openSnackBar,
    getMoreManualDeliveryData,
    manualDeliveriesPagination,
  ]);

  return (
    <ManualDeliveryContext.Provider value={ctxValues}>
      {props.children}
    </ManualDeliveryContext.Provider>
  );
};

export default ManualDeliveryContext;
