/* eslint-disable @typescript-eslint/no-explicit-any */
import * as signalR from '@microsoft/signalr';

class SignalRService {
  private hubConnection?: signalR.HubConnection;
  private token?: string = '';
  private url = window.location.origin;

  constructor(token?: string) {
    this.token = token;
    this.initializeConnection();
  }

  initializeConnection() {
    const retryTimes = [0, 3000, 10000, 60000];

    const signalRUrl = this.url + '/notificationhub';
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(signalRUrl, {
        accessTokenFactory: () => this.token,
      })
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: (context) => {
          const index =
            context.previousRetryCount < retryTimes.length
              ? context.previousRetryCount
              : retryTimes.length - 1;

          return retryTimes[index];
        },
      })
      .build();

    this.hubConnection.start().catch((err) => console.error(err));
  }

  addEventListener(eventName: string, callback: (...args: any[]) => void) {
    if (this.hubConnection) {
      this.hubConnection.on(eventName, callback);
    }
  }

  removeEventListener(eventName: string) {
    if (this.hubConnection) {
      this.hubConnection.off(eventName);
    }
  }
}

export default SignalRService;
