import React, { createContext, useContext } from 'react';
import { SupplierBranding } from '@betotrace/shared/graphql/generated';
import useSupplierBranding from '@betotrace/shared/hooks/use-supplier-branding/use-supplier-branding';

const SupplierBrandingContext = createContext<SupplierBranding>(null);

export const useSupplierBrandingContext = () =>
  useContext(SupplierBrandingContext);

export const SupplierBrandingProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const supplierBranding: SupplierBranding = useSupplierBranding();
  return (
    <SupplierBrandingContext.Provider value={supplierBranding}>
      {children}
    </SupplierBrandingContext.Provider>
  );
};
