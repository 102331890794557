import * as yup from 'yup';

export const ProjectAddressSchema: unknown = yup
  .object()
  .shape({
    street: yup.string().required(),
    city: yup.string().required(),
    countryCode: yup.string().required(),
    location: yup.object().shape({
      latitude: yup.number().required(),
      longitude: yup.number().required(),
    }),
    zipCode: yup.string().required(),
    name: yup.string().required(),
    remarks: yup.string(),
  })
  .required();
