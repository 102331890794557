export enum resourceAccessPermission {
  customer_project_manage = 'customer_project_manage',
  customer_project_product_manage = 'customer_project_product_manage',
  customer_mycompany_manage = 'customer_mycompany_manage',
  customer_user_manage = 'customer_user_manage',
  customer_order_manage = 'customer_order_manage',
  customer_order_create = 'customer_order_create',
  customer_invoiceaddress_manage = 'customer_invoiceaddress_manage',
  customer_manual_delievry_manage = 'customer_manuel_delivery_manage',
  customer_manual_delievry_manage_all = 'customer_manuel_delivery_manage_all',
  customer_order_accept_terms_and_conditions = 'customer_order_accept_terms_and_conditions',

  order_document_remove_own = 'order_document_remove_own',
  order_document_remove_all = 'order_document_remove_all',

  supplier_user_manage = 'supplier_user_manage',
  supplier_branch_manage = 'supplier_branch_manage',
  supplier_delivery_manage = 'supplier_delivery_manage',
  supplier_driver_manage = 'supplier_driver_manage',
  supplier_mycompany_manage = 'supplier_mycompany_manage',
  supplier_order_manage = 'supplier_order_manage',
  supplier_customer_manage = 'supplier_customer_manage',
  supplier_branch_status_manage = 'supplier_branch_status_manage',
  supplier_project_view = 'supplier_project_view',
}

export const customerSettingsAccessPermissions = [
  resourceAccessPermission.customer_mycompany_manage,
  resourceAccessPermission.customer_user_manage,
  resourceAccessPermission.customer_invoiceaddress_manage,
];

export const supplierSettingsAccessPermissions = [
  resourceAccessPermission.supplier_mycompany_manage,
  resourceAccessPermission.supplier_user_manage,
  resourceAccessPermission.supplier_branch_manage,
  resourceAccessPermission.supplier_driver_manage,
];
