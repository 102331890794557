/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useMemo, useState } from 'react';
import {
  Project,
  ProjectDeliveryAddress,
  ProjectMember,
  ProjectMode,
  ProjectStatus,
} from '@betotrace/common';
import { cloneDeep } from 'lodash';

const defaultProjectValue = {
  checkInCode: '',
  checkInRequired: false,
  checkIn: {
    id: '',
  },
  address: {
    city: '',
    countryCode: '',
    location: {
      latitude: 0,
      longitude: 0,
    },
    street: '',
    zipCode: '',
  },
  deliveryAddresses: [],
  id: '',
  invoiceAddressId: '',
  invoiceAddress: {
    address: {
      city: '',
      countryCode: '',
      street: '',
      zipCode: '',
    },
    vat: '',
  },
  members: [],
  name: '',
  reference: '',
  status: '' as ProjectStatus,
};

const ProjectsContext = React.createContext({
  // List projects in listing page
  projectList: [],
  setProjectList: (projects: Project[]) => {},

  // Creating project or selected project
  project: defaultProjectValue,
  setProject: (value: unknown) => {},

  // Check project code is exists or not
  isProjectCodeExist: false,
  setIsProjectCodeExist: (value: boolean) => {},

  // Using mode: 'CREATE' or 'EDIT'
  projectMode: '',
  setProjectMode: (mode: ProjectMode) => {},

  // Check form valid
  isProjectFormValid: false,
  setIsProjectFormValid: (value: boolean) => {},

  /**
   * Add or remove member
   * @param member
   */
  updateProjectMembers: (member: ProjectMember) => {},

  removeProjectMember: (userId: string) => {},

  /**
   * Add or remove delivery address
   * @param member
   */
  updateProjectDeliveryAddress: (
    address: ProjectDeliveryAddress,
    mode: 'update' | 'create' | 'delete'
  ) => {},

  // Reset project form
  resetCtxData: () => {},
});

interface Props {
  children: React.ReactNode;
}

export const ProjectsContextProvider = (props: Props) => {
  const [projectList, setProjectList] = useState<Project[]>([]);
  const [project, _setProject] = useState<Project>(defaultProjectValue);
  const [projectMode, setProjectMode] = useState(ProjectMode.CREATE);
  const [isProjectCodeExist, setIsProjectCodeExist] = useState<boolean>(false);
  const [isProjectFormValid, setIsProjectFormValid] = useState<boolean>(false);

  const setProject = (value: any) => {
    _setProject({
      ...project,
      ...value,
    });
  };

  const updateProjectMembers = (member: ProjectMember) => {
    const memberExist =
      project.members.findIndex((e) => e.userId === member.id) !== -1;
    const newMemberList = memberExist
      ? project.members.filter((e) => e.userId !== member.id)
      : [...project.members, member];
    setProject({ members: newMemberList });
  };

  const removeProjectMember = (userId: string) => {
    const filterProjectMember = project.members.filter(
      (m) => m.userId !== userId
    ) as unknown as ProjectMember[];
    _setProject({ ...project, members: filterProjectMember });
  };

  const updateProjectDeliveryAddress = (
    address: ProjectDeliveryAddress,
    mode = 'create'
  ) => {
    let newDeliveryAddressList;
    const index = project.deliveryAddresses.findIndex(
      (e) => e.id === address.id
    );
    switch (mode) {
      case 'update':
        newDeliveryAddressList = cloneDeep(project.deliveryAddresses);
        newDeliveryAddressList[index] = address;
        break;
      case 'delete':
        newDeliveryAddressList = cloneDeep(project.deliveryAddresses);
        newDeliveryAddressList = newDeliveryAddressList.filter(
          (e, key) => key !== index
        );
        break;
      default:
        newDeliveryAddressList = [...project.deliveryAddresses, address];
        break;
    }
    setProject({ deliveryAddresses: newDeliveryAddressList });
  };

  const resetCtxData = () => {
    _setProject(defaultProjectValue);
    setIsProjectCodeExist(false);
    setIsProjectFormValid(false);
  };

  const value = useMemo(
    () => ({
      projectList,
      setProjectList,
      project,
      setProject,
      isProjectCodeExist,
      setIsProjectCodeExist,
      projectMode,
      setProjectMode,
      isProjectFormValid,
      setIsProjectFormValid,
      updateProjectMembers,
      updateProjectDeliveryAddress,
      resetCtxData,
      removeProjectMember,
    }),
    [
      projectList,
      setProjectList,
      project,
      setProject,
      isProjectCodeExist,
      setIsProjectCodeExist,
      projectMode,
      setProjectMode,
      isProjectFormValid,
      setIsProjectFormValid,
      updateProjectMembers,
      updateProjectDeliveryAddress,
      resetCtxData,
      removeProjectMember,
    ]
  );

  return (
    <ProjectsContext.Provider value={value}>
      {props.children}
    </ProjectsContext.Provider>
  );
};

export default ProjectsContext;
