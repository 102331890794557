import { ProjectDeliveryAddressInput } from '@betotrace/shared/graphql/generated';
import { User } from './user';

export interface ProjectAddressLocation {
  latitude?: number;
  longitude?: number;
}

export interface ProjectAddress {
  city: string;
  countryCode: string;
  location?: ProjectAddressLocation;
  street: string;
  zipCode: string;
  name?: string;
  remarks?: string;
}

export interface ProjectInvoiceContact {
  jobTitle: string;
  fullName: string;
  mobile: string;
  phone: string;
  email: string;
  id: string;
}

export interface ProjectDeliveryAddress {
  address: ProjectAddress;
  id?: string;
  projectId?: string;
}

export interface ProjectInvoiceAddress {
  address: ProjectAddress;
  id?: string;
  vat?: string;
  contact?: ProjectInvoiceContact;
  name?: string;
}

export interface ProjectMember {
  user?: User;
  userId?: string;
  id?: string;
}

export enum ProjectStatus {
  PLANNED = 'PLANNED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  ARCHIVED = 'ARCHIVED',
}

export interface ProjectInvoiceAddressWithProject
  extends ProjectInvoiceAddress {
  isProject?: boolean;
}

export interface Project {
  address: ProjectAddress;
  deliveryAddresses: ProjectDeliveryAddressInput[];
  id: string;
  invoiceAddressId: string;
  invoiceAddress: ProjectInvoiceAddressWithProject;
  members: ProjectMember[];
  name: string;
  reference: string;
  checkInCode: string;
  checkInRequired: boolean;
  status: ProjectStatus;
}

export enum ProjectMode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}
