import { useLoadScript } from '@react-google-maps/api';

const API_KEY = window?._env_?.VITE_GOOGLE_MAPS_API_KEY;
const libraries: (
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'places'
  | 'visualization'
)[] = ['places'];

const useGoogleMapScript = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: API_KEY,
    libraries: libraries,
  });

  return {
    isLoaded,
    loadError,
  };
};

export default useGoogleMapScript;
