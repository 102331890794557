import { useLocation } from 'react-router-dom';
import { publicCustomerPath, publicSupplierPath } from '../../constants/public';

const usePublicPath = () => {
  const location = useLocation();
  const isPublicPath =
    location?.pathname?.includes(publicCustomerPath) ||
    location?.pathname?.includes(publicSupplierPath);

  return isPublicPath;
};

export default usePublicPath;
