/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useKeycloak } from '@react-keycloak/web';
import PublicContext from '../publicContext/public.context';
import {
  generateUserHash,
  getCompanyData,
  pendoInitializator,
  UserInfo,
} from './authHelper/authHelper';
import { getSearchQueries } from '@betotrace/modules/pages/NewStructure/utils/utils';
import {
  DE,
  EN,
  ES,
  FR_BE,
  NL_BE,
  NL_NL,
} from '@betotrace/shared/translations';
import { KeycloakUserTypes } from '../../types/user';
import useIsBetotraceUrl from '../../hooks/useIsBetotraceUrl/useIsBetotraceUrl';
import {
  gleapInitialize,
  setGleapIdentity,
} from '../../utils/gleapConfig/GleapConfig';
import { languageEnum } from '@betotrace/shared/components/languageSelector/helper/helper';

interface AuthContextProps {
  authData: {
    initialized: boolean;
    user: KeycloakUserTypes | null;
    loadingUser?: boolean;
    isLoggedIn: boolean;
    token: string;
  };
  locale: string;
  messages: { [key: string]: string };
  setAuthData: React.Dispatch<
    React.SetStateAction<{
      initialized: boolean;
      isLoggedIn: boolean;
      token: string;
      user: KeycloakUserTypes | null;
    }>
  >;
}

const AuthContext = createContext<AuthContextProps>({
  authData: {
    initialized: false,
    user: null,
    loadingUser: true,
    isLoggedIn: false,
    token: '',
  },
  locale: 'en',
  messages: {},
  setAuthData: () => {},
});

export const AuthContextProvider = (props: { children: JSX.Element }) => {
  const { keycloak, initialized } = useKeycloak();
  const [authData, setAuthData] = useState({
    initialized,
    isLoggedIn: keycloak?.authenticated,
    token: keycloak?.token,
    user: keycloak?.userInfo,
  });
  const [messages, setMessages] = React.useState({});
  const [locale, setLocale] = React.useState('en');
  const userLocale = authData?.user as UserInfo;

  const isBetotraceUrl = useIsBetotraceUrl();

  const publicContext = useContext(PublicContext);

  const contextValue = useMemo(
    () => ({
      authData,
      setAuthData,
      messages,
      locale,
    }),
    [authData, setAuthData, locale]
  );

  useEffect(() => {
    (async () => {
      let userInfo: UserInfo = {};
      if (initialized && keycloak?.authenticated && keycloak?.token) {
        userInfo = await keycloak.loadUserInfo();
        publicContext.setIsPublic(false);
      }

      if (keycloak?.authenticated) {
        const hash = await generateUserHash(keycloak?.token);

        if (isBetotraceUrl || userInfo.business_type === 'SUPPLIER') {
          gleapInitialize(window?._env_?.VITE_GLEAP_API_KEY);
          setGleapIdentity({
            userId: userInfo.sub,
            name: userInfo.name,
            email: userInfo.email,
            local: userInfo.locale,
            hash: hash.data.generateUserHash,
          });
        }

        const {
          data: { data },
        }: {
          data: {
            data:
              | { mySupplier: { id: string; name: string } }
              | { myCustomer: { id: string; name: string } };
          };
        } = await getCompanyData(userInfo, keycloak.token);

        // if (data) {
        //   if (
        //     'mySupplier' in data &&
        //     data.mySupplier?.id &&
        //     data.mySupplier?.name
        //   ) {
        //     pendoInitializator(userInfo, data.mySupplier);
        //   } else if (
        //     'myCustomer' in data &&
        //     data.myCustomer?.id &&
        //     data.myCustomer?.name
        //   ) {
        //     pendoInitializator(userInfo, data.myCustomer);
        //   }
        // }
      }

      setAuthData({
        ...authData,
        initialized,
        isLoggedIn: keycloak?.authenticated,
        token: keycloak?.token,
        user: userInfo,
      });
    })();
  }, [initialized, keycloak?.authenticated]);

  useEffect(() => {
    const queryValue = getSearchQueries();
    let lang = queryValue.get('lang');

    if (userLocale?.locale) {
      lang = userLocale?.locale;
    } else if (queryValue.get('locale')) {
      lang = queryValue.get('locale');
    }
    let translations;

    switch (lang) {
      case languageEnum.NL_NL:
        translations = NL_NL;
        break;
      case languageEnum.NL_BE:
        translations = NL_BE;
        break;
      case languageEnum.FR_BE:
        translations = FR_BE;
        break;
      case languageEnum.DE:
        translations = DE;
        break;
      case languageEnum.ES:
        translations = ES;
        break;
      default:
        translations = EN;
        lang = languageEnum.EN;
    }

    setLocale(lang);
    setMessages(translations);
  }, [locale, userLocale?.locale]);

  useEffect(() => {
    window.getKeycloakToken = () => {
      return keycloak?.token;
    };
  }, [keycloak?.token]);

  return (
    <AuthContext.Provider value={contextValue as AuthContextProps}>
      {props.children}
    </AuthContext.Provider>
  );
};
export default AuthContextProvider;

export const useAuthContext = () => {
  const { authData, locale, messages } = useContext(AuthContext);
  return { ...authData, locale, messages };
};

export const useAuthActions = () => {
  const { keycloak } = useKeycloak();
  return {
    login: keycloak.login,
    logout: keycloak.logout,
  };
};
