import { PublicContext } from '@betotrace/shared/contexts';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import './PassivePopover.scss';

const PassivePopover = () => {
  const intl = useIntl();

  const publicCtx = useContext(PublicContext);

  return (
    <div className="passivePopover" onMouseLeave={publicCtx.handleClosePopover}>
      <div className="passivePopover__title">
        {intl.formatMessage({ id: 'passive.freeAccount' })}
      </div>
      <div className="passivePopover__text">
        {intl.formatMessage({ id: 'passive.limitedAccountInfo' })}
      </div>

      {/* use dangerouslySetInnerHTML to remove html tag, in this case <b></b> */}
      <div
        className="passivePopover__text"
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage(
            { id: 'passive.contactUs' },
            { email: '<b>info@betotrace.com</b>' }
          ),
        }}
      />
    </div>
  );
};

export default PassivePopover;
