import { SxProps } from '@mui/material';
import PopoverMui from '@mui/material/Popover';

interface PopoverProps {
  id?: string;
  children: JSX.Element;
  anchorEl: HTMLElement;
  isOpen: boolean;
  handleClose: () => void;
  style?: SxProps;
  anchorOrigin?: {
    horizontal: 'center' | 'left' | 'right' | number;
    vertical: 'bottom' | 'center' | 'top' | number;
  };
  transformOrigin?: {
    horizontal: 'center' | 'left' | 'right' | number;
    vertical: 'bottom' | 'center' | 'top' | number;
  };
}

const customStyle = {
  '&.MuiPopover-root': {
    pointerEvents: 'unset !important',
  },
};

const Popover = (props: PopoverProps) => {
  const {
    id = 'popoverId',
    children,
    anchorEl,
    isOpen,
    handleClose,
    style,
    anchorOrigin = {
      vertical: 'bottom',
      horizontal: 'right',
    },
    transformOrigin = {
      vertical: 'top',
      horizontal: 'right',
    },
  } = props;

  return (
    <PopoverMui
      id={id}
      open={isOpen}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      sx={{ ...customStyle, ...style }}
    >
      {children}
    </PopoverMui>
  );
};

export default Popover;
