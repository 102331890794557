import { OrderTypes } from '@betotrace/modules/order/pages/details/Details/hooks/useOrder';
import { languageEnum } from '@betotrace/shared/components/languageSelector/helper/helper';
import {
  PublicContext,
  useResponsiveContext,
} from '@betotrace/shared/contexts';
import useIsBetotraceUrl from '@betotrace/shared/hooks/useIsBetotraceUrl/useIsBetotraceUrl';
import { resourceAccessPermission } from '@betotrace/shared/types/resouceAccessPermissions';
import { Permissions, UserType } from '@betotrace/shared/utils';
import {
  AccessTime,
  AssignmentTurnedIn,
  CreditCard,
  Dashboard,
  Help,
  LibraryBooks,
  LocationCity,
  PinDropSharp,
  Settings,
} from '@mui/icons-material';
import Group from '@mui/icons-material/Group';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

interface ButtonProps {
  id?: string;
  name: string;
  navLink: string;
  Icon: React.ElementType;
  openNewWindow?: boolean;
  showOnMobile: boolean;
  disabled?: boolean;
  hide?: boolean;
}

const NavigationLists = () => {
  const isValidBetotraceUrl = useIsBetotraceUrl();
  const { locale } = useIntl();
  const publicCtx = useContext(PublicContext);
  const user = UserType();
  const isCustomer = user === OrderTypes.customer;
  const isSupplier = user === OrderTypes.supplier;
  const responsive = useResponsiveContext();

  const checkIsCustomerAndLanguage = () => {
    if (isCustomer) {
      return 'https://betotrace.com/bouwbedrijf/licentieplan';
    } else {
      return locale === languageEnum.FR_BE
        ? 'https://betotrace.com/fr/entreprise-de-construction/licences-et-prix/'
        : 'https://betotrace.com/betoncentrale/licentieplan';
    }
  };

  const mainItems: Array<ButtonProps> = [
    {
      id: isCustomer
        ? 'navigation-projects'
        : "'navigation-supplier-dashboard'",
      name: isCustomer ? 'Projects' : 'dashboard',
      navLink: '/',
      Icon: isCustomer ? LocationCity : Dashboard,
      showOnMobile: true,
    },
    {
      id: 'navigation-orders',
      name: 'Orders',
      navLink: '/orders',
      Icon: AssignmentTurnedIn,
      showOnMobile: true,
    },
  ];

  const secondItems: Array<ButtonProps> = [
    {
      id: 'navigation-settings',
      name: 'Settings',
      navLink: '/settings',
      Icon: Settings,
      showOnMobile: false,
    },
  ];

  if (isSupplier) {
    mainItems.push({
      id: 'navigation-deliveries',
      name: 'Deliveries',
      navLink: '/deliveries',
      Icon: AccessTime,
      showOnMobile: true,
    });

    secondItems.push(
      {
        name: 'Guides',
        navLink: isCustomer
          ? 'https://betotrace.com/handleidingen/bouwbedrijf'
          : 'https://betotrace.com/handleidingen/betoncentrale',
        Icon: CreditCard,
        openNewWindow: true,
        showOnMobile: true,
      },
      {
        name: 'Licence plan',
        navLink: checkIsCustomerAndLanguage(),
        Icon: LibraryBooks,
        openNewWindow: true,
        showOnMobile: true,
      }
    );

    if (
      Permissions({ key: resourceAccessPermission.supplier_customer_manage })
    ) {
      mainItems.push({
        name: 'invite_customers',
        navLink: '/customers',
        Icon: Group,
        showOnMobile: true,
        disabled: publicCtx.isPublic,
      });
    }

    if (
      !isValidBetotraceUrl &&
      Permissions({ key: resourceAccessPermission.supplier_project_view })
    ) {
      mainItems.push({
        id: 'navigation-orders',
        name: 'Projects',
        navLink: '/projects',
        Icon: LocationCity,
        showOnMobile: true,
      });
    }
  }

  const middleItems: Array<ButtonProps> = [];
  if (isCustomer && isValidBetotraceUrl) {
    middleItems.push({
      name: 'Supplier.Overview',
      navLink:
        locale === languageEnum.NL_BE || locale === languageEnum.NL_NL
          ? 'https://betotrace.com/bouwbedrijf/betoncentrales-overzicht'
          : 'https://betotrace.com/fr/centrales-a-beton/',
      Icon: PinDropSharp,
      openNewWindow: true,
      showOnMobile: true,
    });
  }

  if (isCustomer && isValidBetotraceUrl) {
    secondItems.push(
      {
        name: 'Support',
        navLink:
          locale === languageEnum.FR_BE
            ? 'https://betotrace.com/fr/support/'
            : 'https://betotrace.com/support',
        Icon: Help,
        openNewWindow: true,
        showOnMobile: true,
      },
      {
        name: 'Guides',
        navLink: isCustomer
          ? 'https://betotrace.com/handleidingen/bouwbedrijf'
          : 'https://betotrace.com/handleidingen/betoncentrale',
        Icon: CreditCard,
        openNewWindow: true,
        showOnMobile: true,
      },
      {
        name: 'Licence plan',
        navLink: checkIsCustomerAndLanguage(),
        Icon: LibraryBooks,
        openNewWindow: true,
        showOnMobile: true,
      }
    );
  }

  const secondaryItems =
    responsive.isMobile && !isValidBetotraceUrl ? [] : secondItems;

  return {
    mainItems,
    secondaryItems,
    middleItems,
    showPoweredIcon: !isValidBetotraceUrl,
  };
};

export default NavigationLists;
