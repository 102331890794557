import { createTheme } from '@mui/material';

export const themeBlue = createTheme({
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100% !important',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: 'white !important',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: '#2196F3 !important',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: 'white !important',
        },
      },
    },
  },
});

export const themeYellow = createTheme({
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100% !important',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: '#FFF5E5 !important',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#664D03 !important',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: '#664D03 !important',
        },
      },
    },
  },
});

export const statusBoxInputsTheme = (value: string) => {
  const isYellowStatusBox = value === 'statusYellowWrapper';

  return createTheme({
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            color: `${
              isYellowStatusBox ? '#664D03 !important' : '#fff !important'
            }`,
            fontSize: '16px !important',
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: `${
              isYellowStatusBox ? '#664D03 !important' : '#fff !important'
            }`,
            fontSize: '16px !important',
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          underline: {
            '&:before': {
              borderBottom: `${
                isYellowStatusBox ? '#fff' : '#2196F3'
              } solid 1px !important`,
            },
            '&:hover:not($disabled):before': {
              borderBottom: `${
                isYellowStatusBox ? '#fff' : '#2196F3'
              } solid 1px !important`,
            },
            '&:after': {
              borderBottom: `${
                isYellowStatusBox ? '#fff' : '#2196F3'
              } solid 2px !important`,
            },
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          positionEnd: {
            marginLeft: '0px !important',
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontWeight: 'bold',
          },
        },
      },
    },
  });
};
