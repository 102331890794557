import {
  BelgiumFlag,
  GlobalFlag,
  NederlandFlag,
  GermanFlag,
  SpainFlag,
} from '@betotrace/assets/svg/flags/flags';
interface Languages {
  name: string;
  flag: JSX.Element;
  short: string;
}

export const languageEnum = {
  EN: 'en',
  NL_NL: 'nl-nl',
  NL_BE: 'nl-be',
  FR_BE: 'fr-be',
  DE: 'de',
  ES: 'es',
};

export const languages: Languages[] = [
  {
    name: 'Nederlands - Nederland',
    flag: <NederlandFlag style={{ borderRadius: 5 }} />,
    short: languageEnum.NL_NL,
  },
  {
    name: 'Nederlands - België',
    flag: <BelgiumFlag style={{ borderRadius: 5 }} />,
    short: languageEnum.NL_BE,
  },
  {
    name: 'Français - Belgique',
    flag: <BelgiumFlag style={{ borderRadius: 5 }} />,
    short: languageEnum.FR_BE,
  },
  {
    name: 'English',
    flag: <GlobalFlag style={{ borderRadius: 5 }} />,
    short: languageEnum.EN,
  },
  {
    name: 'Deutsch',
    flag: <GermanFlag style={{ borderRadius: 5 }} />,
    short: languageEnum.DE,
  },
  {
    name: 'Español',
    flag: <SpainFlag style={{ borderRadius: 5 }} />,
    short: languageEnum.ES,
  },
];
