import { GAP } from './gap';

export const MARGIN = {
  XS: GAP[0.5],
  SM: GAP[1],
  MD: GAP[2],
  LG: GAP[3],
  XL: GAP[4],
  XXL: GAP[5],
};
