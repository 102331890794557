import React, { useMemo, useState } from 'react';
import { LoaderWithOverlay } from '@betotrace/shared/components';
import { useParams } from 'react-router-dom';
import {
  OrderConfirmationArticle,
  OrderConfirmationsQuery,
  OrderConfirmationsQueryVariables,
  OrderQuantityUnit,
  ProductVersionSettingsQuantityUnit,
  SortBy,
  useOrderConfirmationsQuery,
} from '@betotrace/shared/graphql/generated';
import { checkProductQuantityUnit } from '@betotrace/shared/utils/productUtils/ProductUtils';

const createData = (
  code: string,
  description: string,
  quantityUnit: OrderQuantityUnit | ProductVersionSettingsQuantityUnit,
  unitPrice: number
) => {
  const unitQuantity = checkProductQuantityUnit(quantityUnit);
  return { code, description, unitQuantity, unitPrice };
};

const OrderStatusReportContext = React.createContext({
  dataOrderConfirmations:
    [] as OrderConfirmationsQuery['orderConfirmations']['items'],
  rows: undefined,
  handleRefetch: (_variables?: OrderConfirmationsQueryVariables) => {},
  statusReportsPagination: null,
});

export const OrderStatusReportContextProvider = (props: {
  children: JSX.Element;
}) => {
  const params = useParams() as { id: string };
  const [dataOrderConfirmations, setDataOrderConfirmations] = useState<
    OrderConfirmationsQuery['orderConfirmations']['items']
  >([]);
  const [statusReportsPagination, setStatusReportsPagination] =
    useState<OrderConfirmationsQuery['orderConfirmations']['pageInfo']>(null);

  const { loading, refetch } = useOrderConfirmationsQuery({
    variables: {
      filter: {
        orderId: params.id,
      },
      page: {
        size: 10,
        index: 0,
      },
      sort: {
        createdOn: SortBy.Desc,
      },
    },
    onCompleted(data) {
      if (data) {
        setDataOrderConfirmations(data.orderConfirmations?.items ?? []);

        if (data.orderConfirmations?.pageInfo) {
          setStatusReportsPagination(data.orderConfirmations.pageInfo);
        }
      }
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRefetch = (variables?: OrderConfirmationsQueryVariables) => {
    refetch({
      ...(variables ?? {}),
    }).then((result) => {
      if (result.data?.orderConfirmations?.items?.length > 0)
        setDataOrderConfirmations(
          result?.data?.orderConfirmations?.items ?? []
        );
      setStatusReportsPagination(result.data?.orderConfirmations.pageInfo);
    });
  };

  const rows = useMemo(
    () => (articles: OrderConfirmationArticle[]) =>
      articles?.map((article) =>
        createData(
          article.code,
          article.description,
          article.quantityUnit as unknown as
            | OrderQuantityUnit
            | ProductVersionSettingsQuantityUnit,
          article.quantity as number
        )
      ),
    []
  );

  const ctxValues = useMemo(
    () => ({
      dataOrderConfirmations,
      rows,
      handleRefetch,
      statusReportsPagination,
    }),
    [dataOrderConfirmations, rows, handleRefetch, statusReportsPagination]
  );

  if (loading) {
    return <LoaderWithOverlay />;
  }

  return (
    <OrderStatusReportContext.Provider value={ctxValues}>
      {props.children}
    </OrderStatusReportContext.Provider>
  );
};

export default OrderStatusReportContext;
