import React from 'react';

import styled from 'styled-components';

import CircularProgress from '@mui/material/CircularProgress';

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 105;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoaderWithOverlay = () => {
  return (
    <Overlay>
      <CircularProgress />
    </Overlay>
  );
};

export default LoaderWithOverlay;
