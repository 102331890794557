import usePublicPath from '@betotrace/shared/hooks/usePublicPath/usePublicPath';
import { UserType } from '@betotrace/shared/utils';
import { IUserOrder } from '../types/useOrderTypes';
import useCustomerOrder from './useCustomerOrder';
import usePublicOrder from './usePublicOrder';
import useSupplierOrder from './useSupplierOrder';

export enum OrderTypes {
  customer = 'customer',
  supplier = 'supplier',
}

const useOrder = () => {
  const userType = UserType();
  const isPublicPath = usePublicPath();

  const customerData = useCustomerOrder();
  const supplierData = useSupplierOrder();
  const publicData = usePublicOrder();

  const getDataByOrderType = (): IUserOrder => {
    if (!userType || isPublicPath) {
      return publicData;
    }

    const cases = {
      [OrderTypes.customer]: customerData,
      [OrderTypes.supplier]: supplierData,
    };

    return cases[userType as OrderTypes];
  };

  return getDataByOrderType();
};

export default useOrder;
