import React, { Suspense, useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  PublicContext,
  StepperContext,
  useAuthContext,
} from '@betotrace/shared/contexts';
import { LoaderWithOverlay, NavBar } from '@betotrace/shared/components';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
import ProtectedRoute from './ProtectedRoute';
import InitialLoader from '../shared/components/initialLoader/InitialLoader';
import './../../index.scss';
import paths from '@betotrace/routes/Path';
import useOrder from '@betotrace/modules/order/pages/details/Details/hooks/useOrder';
import usePublicPath from '@betotrace/shared/hooks/usePublicPath/usePublicPath';

const Routes = () => {
  const { initialized, isLoggedIn, user } = useAuthContext();
  const { isLoading } = useContext(StepperContext);
  const { publicType } = useContext(PublicContext);

  const isPublicPath = usePublicPath();
  const orderDetails = useOrder();
  const { showNavbar, showTopNavbar } = orderDetails?.general ?? {};

  if (!initialized) return <InitialLoader />;

  const getFilteredPaths = () => {
    const isCustomer = user?.business_type === 'CUSTOMER';
    return paths.filter(path => (isCustomer ? !path.isSupplier : path.isSupplier !== false));
  };

  const getNavbarClass = () => {
    if (showTopNavbar || publicType === 'privateCustomer') return 'leftnav onlyTopNav';
    if (isLoggedIn || (showNavbar && isPublicPath)) return 'leftnav';
    return '';
  };

  const renderLoaderOverlay = () => (
    <Box  sx={{
      opacity: '0.4',
      backgroundColor: '#ccc',
      position: 'fixed',
      width: '100%',
      height: '100%',
      top: '0px',
      left: '0px',
      zIndex: '9999999999999',
    }}>
      <CircularProgress sx={{
          position: 'fixed',
          left: '50%',
          top: '50%',
        }} />
    </Box>
  );

  const renderNavBar = () => {
    if (isLoggedIn || (showNavbar && isPublicPath) || showTopNavbar) {
      return <NavBar isLoggedIn={isLoggedIn} user={user} showTopNavbar={showTopNavbar} />;
    }
    return null;
  };

  const renderRoutes = () => {
    const filteredPaths = getFilteredPaths();
    return (
      <div className={getNavbarClass()}>
        <Switch>
          {filteredPaths.map(({ path, component, protectedRoute, ...rest }) => {
            const RouteComponent = protectedRoute ? ProtectedRoute : Route;
            return <RouteComponent key={path} path={path} component={component} {...rest} />;
          })}
        </Switch>
      </div>
    );
  };

  return (
    <>
      {isLoading && renderLoaderOverlay()}
      <Suspense fallback={<LoaderWithOverlay />}>
        {renderNavBar()}
        {renderRoutes()}
      </Suspense>
    </>
  );
};

export default Routes;