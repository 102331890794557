import React, { useContext } from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import {
  PublicContext,
  useResponsiveContext,
} from '@betotrace/shared/contexts';
import './SidebarItem.scss';

interface ButtonProps {
  name: string;
  navLink: string;
  Icon: React.ElementType;
  openNewWindow?: boolean;
  showOnMobile: boolean;
  disabled?: boolean;
  hide?: boolean;
  id?: string;
}

interface Props {
  menuItems: Array<ButtonProps>;
  navHandler: (navLink: string, openNewWindow: boolean) => void;
  active: string;
}

const SidebarItem = ({ menuItems, navHandler, active }: Props) => {
  const { isOpen, isPublic, handleSetPopover } = useContext(PublicContext);
  const { isMobile } = useResponsiveContext();

  return (
    <List
      className="sidebarItem"
      aria-haspopup="true"
      aria-owns={isOpen ? 'navList-id' : undefined}
      onMouseEnter={(event) =>
        isPublic
          ? handleSetPopover(event as unknown as HTMLElement, 'navList-id')
          : null
      }
    >
      {menuItems.map(
        ({
          id,
          name,
          navLink,
          Icon,
          openNewWindow,
          disabled,
          showOnMobile,
          hide,
        }) => {
          if ((isMobile && !showOnMobile) || hide) return null;
          const isActive = navLink === active;

          return (
            <ListItem
              button
              key={name}
              onClick={() => navHandler(navLink, openNewWindow)}
              className={isActive ? 'sidebarItemActive' : ''}
              disabled={disabled}
              id={id}
            >
              <ListItemIcon>
                <Icon className={isActive ? 'sidebarItemIcon' : ''} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <FormattedMessage
                    id={`navbar.${name}`}
                    defaultMessage={name}
                  />
                }
              />
            </ListItem>
          );
        }
      )}
    </List>
  );
};

export default SidebarItem;
