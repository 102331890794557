import { useAuthContext } from '@betotrace/shared/contexts';

// if value is true, we have permission
const Permissions = ({
  key,
  listKeys,
}: {
  key?: string;
  listKeys?: {
    keys: string[];
    all: boolean;
  };
}) => {
  const userData = useAuthContext();

  if (userData?.user) {
    const { webClientRole } = getRolesFromUserData(userData);

    if (key) {
      if (!checkWebClientRole(key, webClientRole)) return false;
    }

    if (listKeys) {
      if (listKeys.all) {
        return listKeys.keys.every((key) => webClientRole?.includes(key));
      } else {
        return listKeys.keys.some((key) => {
          return webClientRole?.includes(key);
        });
      }
    }
  }

  return true;
};

export default Permissions;
// helper functions

const getRolesFromUserData = (userData: {
  user: {
    resource_access: {
      'web-client': {
        roles: string[];
      };
    };
  };
}): { webClientRole: string[] } => {
  const webClientRole = userData.user.resource_access?.['web-client']?.roles;

  return { webClientRole };
};

const checkWebClientRole = (
  webClientKey: string | undefined,
  webClientRole: string[]
) => {
  if (!webClientKey) return true;
  return webClientRole?.includes(webClientKey);
};
