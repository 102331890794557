import BetotraceLargeLogo from '@betotrace/assets/BetotraceLargeLogo.png';
import useOrder from '@betotrace/modules/order/pages/details/Details/hooks/useOrder';
import {
  PublicContext,
  useAuthContext,
  useResponsiveContext,
} from '@betotrace/shared/contexts';
import NavigationLists from '@betotrace/shared/utils/navigationLists/NavigationLists';
import { Box, Divider, Drawer, Toolbar, Typography } from '@mui/material';
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import './Sidebar.scss';
import { SupplierBranding } from '@betotrace/shared/graphql/generated';
import { useSupplierBrandingContext } from '@betotrace/shared/contexts/supplierBranding/supplierBranding.context';
import SidebarItem from './sidebardItem/SidebarItem';
import { languageEnum } from '../languageSelector/helper/helper';

interface Props {
  open: boolean;
  active: string;
  onClose?: () => void;
  navHandler: (navLink: string, openNewWindow: boolean) => void;
}
type DrawerVariant = 'permanent' | 'temporary';

const Sidebar = ({ open = true, active, onClose, navHandler }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
  const history: any = useHistory();
  const { menuItems } = useOrder();
  const { isDesktop } = useResponsiveContext();
  const { isPublic, isOpen, handleSetPopover } = useContext(PublicContext);
  const { mainItems, secondaryItems, middleItems, showPoweredIcon } =
    NavigationLists();
  const supplierBrand: SupplierBranding = useSupplierBrandingContext();
  const { locale } = useAuthContext();

  let poweredByLink;

  switch (locale) {
    case languageEnum.NL_NL:
    case languageEnum.NL_BE:
      poweredByLink = 'https://betotrace.com/features-bouwbedrijven';
      break;
    case languageEnum.FR_BE:
      poweredByLink = 'https://betotrace.com/fr/';
      break;
    default:
      poweredByLink = 'https://betotrace.com/';
      break;
  }

  const desktopDrawerStyle = {
    variant: 'permanent' as DrawerVariant,
    sx: {
      display: isDesktop ? 'block' : 'none',
    },
  };

  const mobileDrawerStyle = {
    variant: 'temporary' as DrawerVariant,
    ModalProps: {
      keepMounted: true, // Better open performance on mobile.
    },
    sx: {
      display: !isDesktop ? 'block' : 'none',
    },
  };

  const drawerProps = isDesktop ? desktopDrawerStyle : mobileDrawerStyle;

  return (
    <Drawer
      {...drawerProps}
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: 257,
        },
      }}
    >
      <Toolbar sx={{ display: 'block' }}>
        {supplierBrand?.logoUrl && (
          <img
            src={
              supplierBrand.logoUrl.startsWith('http')
                ? supplierBrand.logoUrl
                : `https://${supplierBrand.logoUrl}`
            }
            className="sidebarTopLogo"
            onClick={() =>
              isPublic ? null : (history.push('/') as () => void)
            }
            alt="BetoTrace"
          />
        )}
      </Toolbar>
      <Divider />

      {(menuItems?.mainItems.length > 0 || mainItems.length > 0) && (
        <>
          <SidebarItem
            menuItems={menuItems?.mainItems ?? mainItems}
            navHandler={navHandler}
            active={active}
          />
          <Divider />
        </>
      )}

      {(menuItems?.middleItems.length > 0 || middleItems.length > 0) && (
        <SidebarItem
          menuItems={menuItems?.middleItems ?? middleItems}
          navHandler={navHandler}
          active={active}
        />
      )}

      <Box sx={{ marginTop: 'auto' }}>
        {(menuItems?.secondaryItems.length > 0 ||
          secondaryItems.length > 0) && (
          <Box
            onMouseEnter={(event) =>
              isPublic
                ? handleSetPopover(
                    event as unknown as HTMLElement,
                    'navList-id'
                  )
                : null
            }
            aria-owns={isOpen ? 'navList-id' : undefined}
            aria-haspopup="true"
          >
            <SidebarItem
              menuItems={menuItems?.secondaryItems ?? secondaryItems}
              navHandler={navHandler}
              active={active}
            />
          </Box>
        )}

        {showPoweredIcon && (
          <a
            href={poweredByLink}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'none' }}
          >
            <Box className="sidebarBottom">
              <Typography className="sidebarBottomPoweredByText">
                <FormattedMessage
                  id="sidebar.poweredBy"
                  defaultMessage="Powered by"
                />
              </Typography>
              <img
                alt="Betotrace"
                src={BetotraceLargeLogo as string}
                className="sidebarBottomLogo"
              />
            </Box>
          </a>
        )}

        <Box className="sidebarBuildVersion">
          {window?._env_?.VITE_BUILD_VERSION}
        </Box>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
