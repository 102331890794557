/* eslint-disable @typescript-eslint/ban-ts-comment */
import { apiEndpoint } from '@betotrace/common/constants/apiEndpoint';

// we should get user with fetch because API Context Provider is below Auth Context Provider
export const generateUserHash = async (token: string) => {
  const generatedHash = await fetch(apiEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      query: `mutation GenerateUserHash {
                  generateUserHash
                  }
            `,
    }),
  });

  return (await generatedHash.json()) as { data: { generateUserHash: string } };
};

export interface OptionsType {
  operationName: string;
  query: string;
}

export interface UserInfo {
  sub?: string;
  name?: string;
  email?: string;
  locale?: string;
  business_type?: 'SUPPLIER' | 'CUSTOMER';
  customer_id?: string;
  supplier_id?: string;
}

export const getSupplierOptions = () => {
  const query = `query mySupplier {
    mySupplier {
      id
      name
    }
  }`;
  const options: OptionsType = {
    operationName: `mySupplier`,
    query: query,
  };

  return options;
};

export const getCustomerOptions = () => {
  const query = `query myCustomer {
    myCustomer {
      id
      name
    }
  }`;

  const options: OptionsType = {
    operationName: `myCustomer`,
    query: query,
  };
  return options;
};

export const getCompanyData = async (
  userInfo: UserInfo,
  token: string
): Promise<{
  data: {
    data:
      | { mySupplier: { id: string; name: string } }
      | { myCustomer: { id: string; name: string } };
  };
}> => {
  const { customer_id, supplier_id } = userInfo;

  if (!customer_id && !supplier_id) {
    return null;
  }

  let options: OptionsType | Record<string, unknown> = {};

  if (supplier_id) {
    options = getSupplierOptions();
    try {
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          ...options,
        }),
      });

      return {
        data: (await response.json()) as {
          data: { mySupplier: { id: string; name: string } };
        },
      };
    } catch (error) {
      return null;
    }
  } else if (customer_id) {
    options = getCustomerOptions();

    try {
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          ...options,
        }),
      });

      return {
        data: (await response.json()) as {
          data: { myCustomer: { id: string; name: string } };
        },
      };
    } catch (error) {
      return null;
    }
  } else {
    return null;
  }
};

// pendo
export const pendoInitializator = <T extends { id: string; name: string }>(
  userInfo: UserInfo,
  userData: T
) => {
  // @ts-ignore
  // pendo.initialize({
  //   visitor: {
  //     id: userInfo?.sub,
  //     email: userInfo?.email,
  //     full_name: userInfo?.name,
  //     profileLanguage: userInfo?.locale,
  //   },
  //   account: {
  //     id: userData?.id,
  //     name: userData?.name,
  //   },
  // });
};
