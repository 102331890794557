import { ProjectMember } from '@betotrace/common';

export const camelCaseFormatter = (str: string): string => {
  return str
    ? str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
          index === 0 ? word.toLowerCase() : word.toUpperCase()
        )
        .replace(/\s+/g, '')
    : '';
};

export const statusText = (str: string): string => {
  return str
    ? str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, (word: string, index: number) =>
          index === 0 ? word.toUpperCase() : word.toLowerCase()
        )
        .split('_')
        .join(' ')
    : '';
};

export const projectInitials = (projectName: string): string | undefined => {
  const initials = projectName.match(/\b\w/g);
  return initials ? initials.join('').substring(0, 2).toUpperCase() : undefined;
};

export const statusFormatter = (status: string): string => {
  return status
    ? status
        .toLowerCase()
        .split('_')
        .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
        .join(' ')
    : '';
};

export const memberListFormatter = (members: ProjectMember[]): string => {
  return members.length === 0 ? '[]' : JSON.stringify(members, null, 2);
};

export const enumFormatter = (enumValue: string): string => {
  return enumValue
    ? enumValue
        .toLowerCase()
        .split('_')
        .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
        .join(' ')
    : '';
};
