import useOrder from '@betotrace/modules/order/pages/details/Details/hooks/useOrder';
import { PublicContext, useAuthActions } from '@betotrace/shared/contexts';
import usePublicPath from '@betotrace/shared/hooks/usePublicPath/usePublicPath';
import { KeycloakUserTypes } from '@betotrace/shared/types/user';
import React, { MouseEvent, useContext, useState } from 'react';
import LanguageSelector from '@betotrace/shared/components/languageSelector/LanguageSelector';
import { Avatar, Menu as MenuComponent, MenuItem } from '@mui/material';
import { GetInitials } from '@betotrace/shared/utils';
import { FormattedMessage } from 'react-intl';
import { gleapLogout } from '@betotrace/shared/utils/gleapConfig/GleapConfig';

interface Props {
  window?: () => Window;
  isLoggedIn: boolean;
  user: KeycloakUserTypes;
  showTopNavbar?: boolean;
}

export const UserMenu = ({ isLoggedIn, user }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const { logout } = useAuthActions();

  const isPublicPath = usePublicPath();
  const publicCtx = useContext(PublicContext);
  const getDetailsData = useOrder();

  if (!isLoggedIn && !isPublicPath) {
    return null;
  }
  const logoutHandler = () => {
    logout();
    gleapLogout();
  };

  return (
    <>
      <div className="navHeader__right">
        <LanguageSelector />
        {getDetailsData?.userMenu && getDetailsData.userMenu.show && (
          <Avatar
            className={'profileIcon'}
            aria-controls="user-menu"
            aria-haspopup="true"
            onClick={(event) =>
              getDetailsData.userMenu.disabled ? null : handleClick(event)
            }
            onMouseEnter={(event) =>
              getDetailsData.userMenu.disabled
                ? publicCtx.handleSetPopover(
                    event as unknown as HTMLElement,
                    'navBar-id'
                  )
                : null
            }
            aria-owns={publicCtx.isOpen ? 'navBar-id' : undefined}
          >
            {GetInitials(user?.name)}
          </Avatar>
        )}
      </div>

      <MenuComponent
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        disableScrollLock
      >
        <MenuItem onClick={() => logoutHandler()}>
          <FormattedMessage id="navbar.logout" defaultMessage="Logout" />
        </MenuItem>
      </MenuComponent>
    </>
  );
};
