import { languageEnum } from '@betotrace/shared/components/languageSelector/helper/helper';

export function getLang(locale: string) {
  let dateLang = 'en-GB';

  if (locale === languageEnum.FR_BE) {
    dateLang = 'fr-FR';
  } else if (locale === languageEnum.NL_NL || locale === languageEnum.NL_BE) {
    dateLang = 'nl-NL';
  } else if (locale === languageEnum.DE) {
    dateLang = 'de';
  } else if (locale === languageEnum.ES) {
    dateLang = 'es';
  }

  return dateLang;
}
