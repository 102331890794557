import { PublicContext } from '@betotrace/shared/contexts';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import './PassivePopover.scss';

const ShareOrderPopover = () => {
  const intl = useIntl();
  const publicCtx = useContext(PublicContext);

  return (
    <div className="passivePopover" onMouseLeave={publicCtx.handleClosePopover}>
      <div className="passivePopover__title">
        {intl.formatMessage({ id: 'passive.shared.freeAccount' })}
      </div>
      <div className="passivePopover__text">
        {intl.formatMessage({ id: 'passive.shared.limitedAccountInfo' })}
      </div>

      {/* use dangerouslySetInnerHTML to remove html tag, in this case <b></b> */}
      <div
        className="passivePopover__text"
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage(
            { id: 'passive.shared.contactUs' },
            {
              login: `<a href='https://app.betotrace.com' target='_blank'><b>${intl.formatMessage(
                { id: 'passive.shared.login' }
              )}</b><a/>`,
              register: `<a href='https://app.betotrace.com/registration' target='_blank'><b>${intl.formatMessage(
                { id: 'passive.shared.register' }
              )}</b><a/>`,
            }
          ),
        }}
      />
    </div>
  );
};

export default ShareOrderPopover;
