import React, { ErrorInfo } from 'react';
import errorImg from '@betotrace/assets/svg/ErrorBoundary/errorBoundary.svg';
import { Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import './ErrorBoundary.scss';

interface State {
  error: Error;
  errorInfo: ErrorInfo;
}

interface Props {
  children: JSX.Element;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error: Error, errorInfo: ErrorInfo) {
    // Update state so the next render will show the fallback UI.
    return { error, errorInfo };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  handleClick = () => {
    window.history.back();
  };

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div className="error-container">
          <img src={errorImg as string} alt="" className="error-img" />
          <h2 className="general-message">
            <FormattedMessage
              id="somethingWentWrong"
              defaultMessage="Something went wrong"
            />
          </h2>
          <h4 className="error-message">
            <FormattedMessage
              id="unknownErrorMessage"
              defaultMessage="BetoTrace has encountered and unknown error. If this problem persists, contact us at info@betotrace.com"
            />
          </h4>
          <Button
            className="back-button"
            variant="contained"
            onClick={this.handleClick}
          >
            <FormattedMessage
              id="returnToLastPage"
              defaultMessage="RETURN TO LAST PAGE"
            />
          </Button>
          <details className="details">
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack.slice(0, 150)}
          </details>
        </div>
      );
    }
    return <>{this.props.children}</>;
  }
}

export default ErrorBoundary;
