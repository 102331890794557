/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { useEffect, useState } from 'react';
import { UpdateOrderDetailsByProductDetails } from '@betotrace/shared/utils';
import { Project } from '@betotrace/shared/graphql/generated';

interface Props {
  children: React.ReactNode;
}

const StepperContext = React.createContext({
  isStepValid: false,
  stepHandler: () => undefined,
  projects: [],
  projectList: (project: Project) => undefined,
  selProject: {},
  isAddProject: false,
  stepAddProjectHandler: (check: boolean) => undefined,
  newProjects: (item: Project) => undefined,
  steperaddProject: {},
  products: [],
  useSetProducts: (data) => {},
  setSelProducts: (products) => {},
  selProduct: {},
  plants: [],
  setFinalProject: (project) => {},
  isPlantValid: false,
  plantHandler: () => {},
  selPlant: {},
  setPlantSel: (plant) => {},
  productDetails: [],
  finalProject: {},
  orderDetails: {},
  setOrderDetails: (data) => {},
  deliveryAddresses: [],
  productDetailsErrors: {},
  setProductDetailsError: (data) => {},
  productDetailsData: [],
  setProductDetailsData: (data) => {},
  productType: 'new',
  useSetProductType: (data) => {},
  populatedData: {},
  setPopulatedData: (data) => {},
  setProducts: (data) => {},
  setProjects: (data) => {},
  originalDeliveryList: [],
  setOriginalDeliveryList: (data) => {},
  stepperState: false,
  setStepperState: (value: boolean) => undefined,
  predefinedSet: false,
  setPredefinedSet: (value: boolean) => undefined,
  initialProductDetailsData: false,
  setInitialProductDetailsData: (value: boolean) => undefined,
  resetCtxData: () => {
    // This method is to reset the stepper context
  },
  stepperProjectID: '',
  setStepperProjectID: (value: string) => undefined,
  projectOrderTab: false,
  setProjectOrderTab: (value: boolean) => undefined,
  projectProductTab: false,
  setProjectProductTab: (value: boolean) => undefined,
  customProdTitle: false,
  setCustomProdTitle: (value: boolean) => undefined,
  customProdQty: false,
  setCustomProdQty: (value: boolean) => undefined,
  productDetailsPaceError: false,
  setProductDetailsPaceError: (value: boolean) => undefined,
  productDetailsQuantity: 0,
  setProductDetailsQuantity: (value: number) => undefined,
  productDetailsPaceQuantity: 0,
  setProductDetailsPaceQuantity: (value: number) => undefined,
  isLoading: false,
  setIsLoading: (value: boolean) => undefined,
  setProductDetailsAddError: (value: boolean) => undefined,
  productDetailsAddError: false,
  setProductDetailsAddressValidation: (value: boolean) => undefined,
  productDetailsAddressValidation: false,
  isOrderDetail: false,
  setIsOrderDetail: (value: boolean) => undefined,
  setOrderId: (value: string) => undefined,
  orderId: '',
});
export const StepperContextProvider = (props: Props) => {
  const [valid, setValid] = useState(false);
  const [projects, setProjects] = useState([]);
  const [selProject, setSelProject] = useState({});
  const [addProject, setAddProject] = useState(false);
  const [steperaddProject, setSteperaddProject] = useState({});
  const [products, setProducts] = useState([]);
  const [selProduct, setSelProduct] = useState({});
  const [plantValid, setPlantValid] = useState(false);
  const [plants, setPlants] = useState([]);
  const [selPlant, setSelPlant] = useState({});
  const [finalProject, setFinalProject] = useState({});
  const [orderDetails, setOrderDetails] = useState({});
  const [deliveryAddresses, setDeliveryAddresses] = useState([]);
  const [productDetailsErrors, setProductDetailsError] = useState({});
  const [productDetailsData, setProductDetailsData] = useState([]);
  const [originalDeliveryList, setOriginalDeliveryList] = useState([]);
  const [productType, setProductType] = useState('new');
  const [populatedData, setPopulatedData] = useState({});
  const [stepperState, setStepperState] = useState(false);
  const [stepperProjectID, setStepperProjectID] = useState('');
  const [projectOrderTab, setProjectOrderTab] = useState(false);
  const [projectProductTab, setProjectProductTab] = useState(false);
  const [predefinedSet, setPredefinedSet] = useState(false);
  const [initialProductDetailsData, setInitialProductDetailsData] =
    useState(false);
  const [customProdTitle, setCustomProdTitle] = useState(false);
  const [customProdQty, setCustomProdQty] = useState(false);
  const [productDetailsPaceError, setProductDetailsPaceError] = useState(false);
  const [productDetailsQuantity, setProductDetailsQuantity] = useState(0);
  const [productDetailsPaceQuantity, setProductDetailsPaceQuantity] =
    useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [productDetailsAddError, setProductDetailsAddError] = useState(false);
  const [productDetailsAddressValidation, setProductDetailsAddressValidation] =
    useState(false);
  const [isOrderDetail, setIsOrderDetail] = useState(false);
  const [orderId, setOrderId] = useState('');

  const stepHandler = () => {
    setValid(true);
  };
  const plantHandler = () => {
    setPlantValid(true);
  };
  const useSetProductType = (type: string) => {
    setProductType(type);
  };
  const useSetProducts = (data) => {
    setProducts(data);
  };

  const setPlantSel = (plant) => {
    setSelPlant(plant);
  };
  const projectList = (project: Project) => {
    setProjects([...projects, project]);
  };
  const stepAddProjectHandler = (check: boolean) => {
    setAddProject(check);
  };
  const newProjects = (data: Project) => {
    setFinalProject(data);
    setDeliveryAddresses([{ address: data.address }]);
    setSteperaddProject(data);
  };
  const setSelProducts = (product) => {
    setSelProduct(product);
  };
  const resetCtxData = () => {
    setValid(false);
    setSelProject({});
    setAddProject(false);
    setSteperaddProject({});
    setSelProduct({});
    setPlantValid(false);
    setSelPlant({});
    setFinalProject({});
    setOrderDetails({});
    setDeliveryAddresses([]);
    setProductDetailsError({});
    setProductDetailsData([]);
    setOriginalDeliveryList([]);
    setProductType('new');
    setPopulatedData({});
    setPredefinedSet(false);
    setInitialProductDetailsData(false);
    setProjectOrderTab(false);
    setProjectProductTab(false);
    setProductDetailsPaceError(false);
    setProductDetailsQuantity(0);
    setProductDetailsPaceQuantity(0);
    setProductDetailsAddError(false);
  };
  return (
    <StepperContext.Provider
      value={{
        isStepValid: valid,
        stepHandler,
        setProjects,
        projects,
        projectList,
        selProject,
        stepAddProjectHandler,
        isAddProject: addProject,
        newProjects,
        steperaddProject,
        products,
        useSetProducts,
        setSelProducts,
        selProduct,
        plants,
        isPlantValid: plantValid,
        plantHandler,
        setPlantSel,
        selPlant,
        finalProject,
        setFinalProject,
        orderDetails,
        setOrderDetails,
        deliveryAddresses,
        productDetailsErrors,
        setProductDetailsError,
        productDetailsData,
        setProductDetailsData,
        setProducts,
        originalDeliveryList,
        setOriginalDeliveryList,
        productType,
        useSetProductType,
        populatedData,
        setPopulatedData,
        resetCtxData,
        stepperState,
        setStepperState,
        stepperProjectID,
        setStepperProjectID,
        projectOrderTab,
        setProjectOrderTab,
        projectProductTab,
        setProjectProductTab,
        predefinedSet,
        setPredefinedSet,
        initialProductDetailsData,
        setInitialProductDetailsData,
        customProdTitle,
        setCustomProdTitle,
        customProdQty,
        setCustomProdQty,
        productDetailsPaceError,
        setProductDetailsPaceError,
        productDetailsQuantity,
        setProductDetailsQuantity,
        productDetailsPaceQuantity,
        setProductDetailsPaceQuantity,
        isLoading,
        setIsLoading,
        productDetailsAddError,
        setProductDetailsAddError,
        setProductDetailsAddressValidation,
        productDetailsAddressValidation,
        setIsOrderDetail,
        isOrderDetail,
        setOrderId,
        orderId,
      }}
    >
      {props.children}
    </StepperContext.Provider>
  );
};
export default StepperContext;
